.component-banner-e {
  padding-top: 20px;
  .row {
    background-position: center 30%;
  }
  .breadcrumbs {
    text-align: center;
    @media screen and (min-width: $md-breakpoint) {
      border-top: 1px solid #d1d1d1;
      text-align: left;
      color: inherit;
      margin-top: 10px;
    }
    
    ul {
      padding: 0;
      margin: 0;
    }
    ul li {
      display: inline-block;
      color: $mediumGrey;
    }
    ul li:not(:last-child):after {
      content: ">";
      display: inline-block;
      font-size: 12px;
      vertical-align: middle;
      padding-left: 3px;
    }
    ul li a,
    ul li span {
      font-size: 12px;
      color: $mediumGrey;
      line-height: 16px;
    }
  }
  h1 {
    font-size: 33px;
    line-height: 44px;
    text-align: left;
    @media screen and (min-width: $sm-breakpoint) {
      font-size: 45px;
      line-height: 56px;
    }
  }
  .banner-title {
    font-size: 22px;
    line-height: 25px;
    font-family: $pFontBold;
    margin-bottom: 0;
    margin-top: 0;
    text-align: center;
    color: $globalGrey;
    @media screen and (min-width: $md-breakpoint) {
      text-align: left;
    }
  }

  .banner-body {
    margin: 0;
  }
  h1.multicolor-block span {
    display: block;
  }
}

// search container styles
.search-results {
  .row-inside {
    display: flex;
    justify-content: center;
  }
  .component-banner-e {
    @media screen and (min-width: $md-breakpoint) {
      padding-top: 20px;
    }
    padding-top: 40px;
    .row-inside {
      padding-bottom: 40px;
      @media screen and (min-width: $md-breakpoint) {
        padding-bottom: 64px;
      }
    }
  }
  .internal-search-container {
    #search-results {
      margin-top: 10px;
      .sresult {
        border-bottom: 1px solid #eee;
        padding: 30px 0;
        .sresult-title {
          margin: 0;
          a {
            font-size: 18px;
            line-height: 1.5;
            font-weight: 400;
            @media screen and (min-width: $sm-breakpoint) {
              font-family: $sFontLight;
              font-size: 22px;
            }
          }
        }
        .sresult-url {
          margin-top: 0;
          color: #999;
          font-size: 12px;
          line-height: 25px;
          word-wrap: break-word;
        }
        .sresult-snippet {
          margin-top: 10px;
          margin-bottom: 0;
          font-size: 14px;
          color: #55565a;
          line-height: 25px;
        }
      }
      .sresult-error {
        -webkit-font-smoothing: antialiased;
        color: #55565a;
        font-size: 15px;
        line-height: 25px;
      }
      &.fetching {
        opacity: 0.3;
      }
    }
    .search-term {
      font-family: $sFontLight;
      font-size: 18px;
      color: #999;
      margin-bottom: 0;
      @media screen and (min-width: $md-breakpoint) {
        font-size: 24px;
      }
      .search-term-tk {
        color: $globalGrey;
      }
    }
    .search-selectors {
      position: relative;
      padding: 10px 0;
      margin-bottom: 0;
      margin-top: 20px;
      border-bottom: 1px solid #f2f2f2;
      @media screen and (min-width: $sm-breakpoint){
        position: static;
        padding: 0;
      }
      a {
        display: block;
        padding: 10px 0 20px;
        margin: 0 30px 0 0;
        color: #323031;
        @media screen and (min-width: $sm-breakpoint){
          display: inline-block;
        }
        &:hover {
          color: #00a1df;
        }
        &.active {
          border-bottom: 0;
          color: #00a1df;
          @media screen and (min-width: $sm-breakpoint){
            border-bottom: 2px solid #00a1df;
          }
          &:before {
            content: "Showing results from:";
            margin-right: 10px;
            color: #333;
            @media screen and (min-width: $sm-breakpoint) {
              content: '';
              margin-right: 0;
            }
          }
        }
        &:not(.active) {
          display: none;
          @media screen and (min-width: $sm-breakpoint) {
            display: inline-block;
            padding: 10px 0 20px;
            margin: 0 30px 0 0;
          }
        }
      }

      .selector-arrow {
        content: "";
        position: absolute;
        top: 25px;
        right: 0;
        display: inline-block;
        width: 20px;
        height: 11px;
        background-image: url(https://www.mulesoft.com/sites/default/files/2018-10/down-carrot.svg);
        background-position: 0 0;
        @media screen and (min-width: $sm-breakpoint) {
          content: none;
          display: block;
          background: none;
          height: inherit;
          position: inherit;
        }
      }

      &.open {
        padding-top: 50px;
        a {
          display: block;
        }
        a.active {
          position: absolute;
          top: 10px;
        }
        .selector-arrow {
          -ms-transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
        }
      }

    }
    .pager {
      padding: 40px 0 80px;
      margin-top: 20px;
      text-align: center;
      a {
        display: inline-block;
        vertical-align: middle;
        margin: 0 5px;
        color: $globalGrey;
        padding: 0 2px 7px;
        border-bottom: 2px solid transparent;
        @media screen and (min-width: $sm-breakpoint) {
          margin: 0 20px;
        }
        &:hover {
          color: #00a1df;
        }
        &.active {
          color: #00a1df;
          pointer-events: none;
          border-bottom: 2px solid #00a1df;
        }
      }
    }
  }
}

