// Arrow Button
a, p {
  text-decoration: none;
  &.arrow-button {
    color: $muleBlue;
    font-weight: bold;
    text-decoration: none;
    font-family: $sFont, sans-serif;
    font-size: 16px;
    line-height: 24px;
    display: grid;
    grid-template-columns: 30px 1fr;
    width: fit-content;
    &.white {
      color: $white;
    }
    &:hover, &:focus {
      text-decoration: underline;
      text-decoration-thickness: 2px;
      text-underline-offset: 3px;
      color: $darkNavyBlue;
      &:before {
        background-color: $darkNavyBlue;
      }
    }
    &:before {
      display: inline-block;
      background-image: url("/src/img/carrot-white.svgz");
      background-repeat: no-repeat;
      background-size: 40%;
      background-position: 8px 6px;
      background-color: $muleBlue;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      margin-right: 10px;
      margin-top: 0;
      content: " ";
      vertical-align: middle;
    }
    &.white{
      &:hover{
        color: $white;
        opacity: .9;
      }
      &:before {
        background-color: $white;
        background-image: url("/src/img/carrot-blue.svgz");
        display: inline-block;
        background-repeat: no-repeat;
        background-size: 40%;
        background-position: 8px 6px;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        margin-right: 10px;
        margin-top: 0;
        content: " ";
        vertical-align: middle;
      }
      &:before:hover {
        opacity: .9;
        background-color: $white;
      }
    }
  }
}


.cta.solid{
  width: 200px;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  &.blue{
    border: 1px solid $darkNavyBlue;
    background: $darkNavyBlue;
    font-family: $sFont;
    color: $white;
    &:hover{
      background: transparent;
      color: $darkNavyBlue;
      transition: background 0.15s ease-in-out, color 0.15s ease-in-out;
    }
  }
  &.indigo{
    border: 1px solid $indigo-50;
    background: $indigo-50;
    font-family: $sFont;
    color: $white;
    border-radius: 4px;
    &:hover{
      background: transparent;
      color: $indigo-50;
      transition: background 0.15s ease-in-out, color 0.15s ease-in-out;
    }
  }
  &.mule-blue{
    border: 1px solid $muleBlue;
    background: $muleBlue;
    font-family: $sFont;
    color: $white;
    &:hover{
      background: transparent;
      color: $muleBlue;
      transition: background 0.15s ease-in-out, color 0.15s ease-in-out;
    }
  }
  &.purple{
    border: 1px solid $purple;
    background: $purple;
    font-family: $sFont;
    color: $white;
    &:hover{
      background: transparent;
      color: $purple;
      transition: background 0.15s ease-in-out, color 0.15s ease-in-out;
    }
  }
  &.magenta{
    border: 1px solid $magenta;
    background: $magenta;
    font-family: $sFont;
    color: $white;
    &:hover{
      background: transparent;
      color: $magenta;
      transition: background 0.15s ease-in-out, color 0.15s ease-in-out;
    }
  }
  &.navy-blue{
    border: 1px solid $navyBlue;
    background: $navyBlue;
    font-family: $sFont;
    color: $white;
    &:hover{
      background: transparent;
      color: $navyBlue;
      transition: background 0.15s ease-in-out, color 0.15s ease-in-out;
    }
  }
}


.cta.outline{
  width: 200px;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  &.blue{
    border: 1px solid $darkNavyBlue;
    background: transparent;
    font-family: $sFont;
    color: $darkNavyBlue;
    &:hover{
      background: $darkNavyBlue;
      color: $white;
      transition: background 0.15s ease-in-out, color 0.15s ease-in-out;
    }
  }
  &.indigo{
    border: 1px solid $indigo;
    background: transparent;
    font-family: $sFont;
    color: $indigo;
    &:hover{
      background: $indigo;
      color: $white;
      transition: background 0.15s ease-in-out, color 0.15s ease-in-out;
    }
  }
  &.magenta{
    border: 1px solid $magenta;
    background: transparent;
    font-family: $sFont;
    color: $magenta;
    &:hover{
      background: $magenta;
      color: transparent;
      transition: background 0.15s ease-in-out, color 0.15s ease-in-out;
    }
  }
  &.purple{
    border: 1px solid $purple;
    background: transparent;
    font-family: $sFont;
    color: $purple;
    &:hover{
      background: $purple;
      color: transparent;
      transition: background 0.15s ease-in-out, color 0.15s ease-in-out;
    }
  }
}


//WES buttons
a,span {
  &.mule-button-br {
    border-radius: 4px;
    font-size: 16px;
    line-height: 28px;
    min-width: initial !important;
    margin-right: 15px;
    margin-bottom: 15px;
    border: 2px solid $muleBlue;
    display: inline-block;
    font-family: $sFontBold;
    text-decoration: none;
    box-shadow: none;
    text-shadow: none;
    text-align: center;
    cursor: pointer;
    background: 0 0;
    color: $muleBlue;
    height: auto;
    padding: 8px 24px;
    min-height: 44px;
    &:hover {
      text-decoration: none;
      background: $cloud-blue-95;
      color: $blue-20;
      border-color: $blue-20;
    }
    &.large {
      height: 52px;
      padding: 10px 24px;
      min-height: 52px;
    }
    &.navy-blue {
      background: 0 0;
      color: $blue-20;
      border: 2px solid $blue-20;
      &:hover {
        background: $blue-20;
        border: 2px solid $blue-20;
        color: $white;
      }
    }
    &.solid {
      background: $blue-50;
      border: 2px solid $blue-50;
      color: $white;
      &:hover {
        background: $blue-20;
        border: 2px solid $blue-20;
        color: $white;
      }
      &.button-banner {
        color: $blue-95;
        border: 2px solid $blue-10;
        background: $blue-10;
        &:hover {
          color: $blue-10;
          border: 2px solid $blue-10;
          background: $white;
        }
      }
    }

  }
}