.page-mulesoft-products-licensing {
  .section-main-content-mulesoft-products-licensing {
    h1,h2,h3,h4,h5,h6,p {
      margin-block: revert;
    }
    h1 {
      font-size: 32px;
      line-height: 40px;
      @media screen and (min-width: $md-breakpoint) {
        font-size: 40px;
        line-height: 48px;
      }
    }
    h2 {
      font-size: 24px;
      line-height: 32px;
      @media screen and (min-width: $md-breakpoint) {
        font-size: 32px;
        line-height: 40px;
      }
    }
    p {
      //font-size: 17px;
      font-size: 16px;
      line-height: 150%;
      &.download-title {
        font-size: 15px;
        text-align: left;
        padding-inline-start: 25px;
      }
    }

    ul {
      text-align: left;
    }

    .table-container {
      overflow-x: auto;
    }
  }
}