.component-banner-f {
  .page-title-container {
    text-align: center;
    @media screen and (min-width: $md-breakpoint) {
      padding-bottom: 30px;
    }
    h2 {
      color: $white;
      font-size: 22px;
      text-align: left;
      margin-bottom: 0;
      line-height: 30px;
      @media screen and (min-width: $md-breakpoint) {
        padding-bottom: 10px;
        border-bottom: 1px solid #fff;
      }
    }
  }

  .row-inside {
    .banner-content-container {
      text-align: center;
      h1 {
        font-size: 31px;
        line-height: 1.2;
        font-family: $pFont;
        padding-top: 30px;
        padding-bottom: 50px;
        @media screen and (min-width: $md-breakpoint) {
          text-align: left;
          padding: initial;
        }
      }
      img {
        max-width: 200px;
        padding-bottom: 30px;
        @media screen and (min-width: $md-breakpoint) {
          padding-bottom: initial;
        }
      }
      @media screen and (min-width: $md-breakpoint){
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .banner-title {
        @media screen and (min-width: $md-breakpoint){
          order: 2;
          flex-basis: 40%;
        }
      }
      .banner-img {
        @media screen and (min-width: $md-breakpoint){
          text-align: center;
          flex-basis: 25%;
        }
      }
    }
  }
}