html {
  scroll-behavior: smooth;
}
.tab-system-section{
  max-width: $global-width;
  margin-left: auto;
  margin-right: auto;
  margin-top: -115px;
  position: relative;
  @media screen and (min-width: $md-breakpoint){
    margin-top: -130px;
  }
  @media screen and (min-width: $lg-breakpoint){
    margin-top: -130px;
  }
  .row-inside {
    padding-top: 24px;
    padding-bottom: 0;
    .tabs-title {
      margin-top: 0;
      margin-bottom: 0;
    }
    .tabs-body {
      margin-top: 8px;
      margin-bottom: 25px;
      font-size: 22px;
      line-height: 32px;
    }
  }
  .component-tab-system-nav {
    position: relative;
    .top {
      width: 100%;
      overflow: hidden;
      z-index: 10;
      .row-inside {
        padding: 0;
        .col-xs-12 {
          padding-right: 0;
          padding-left: 0;
          .tabs-nav-wrapper {
            background: $white;
            z-index: 10;
            width: 100%;
            overflow-x: auto;
            scroll-behavior: smooth;
            scrollbar-width: none;
            &::-webkit-scrollbar {
              display: none;
            }
            .tabs-items{
              display: inline-flex;
              height: 66px;
              flex-direction: row;
              flex-wrap: wrap;
              gap: 32px;
              box-sizing: border-box;
              -ms-overflow-style: -ms-autohiding-scrollbar;
              -webkit-overflow-scrolling: touch;
              text-align: center;
              min-width: max-content;
              border-bottom: 1px solid #CED8E1;
              transition: transform 0.5s ease-in-out;

              &:first-child{
                margin-left: 16px;
                @media screen and (min-width: $sm-breakpoint){
                  margin-left: 32px;
                }
                @media screen and (min-width: $md-breakpoint){
                  margin-left: 16px;
                }
              }
              &:last-child{
                margin-right: 16px;
                @media screen and (min-width: $sm-breakpoint){
                  margin-right: 32px;
                }
                @media screen and (min-width: $md-breakpoint){
                  margin-right: 16px;
                }
              }

              .tab-nav-title{
                background: transparent;
                border: transparent;
                border-bottom: 3px solid transparent;
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: $pFontBold;
                font-size: 24px;
                text-transform: capitalize;
                color: $blue-20;
                margin-bottom: 0;
                margin-top: 0;
                cursor: pointer;
                box-sizing: content-box;
                padding: 0;
                &.active{
                  border-bottom: 3px solid $muleBlue;
                }
                &:hover {
                  @media (hover: hover) {
                    //border-bottom: 3px solid $muleBlue;
                    color: $blue-50;
                  }

                }
              }
            }
          }
        }
      }
    }
    .row-inside{
      padding-top: 0;
      padding-bottom: 0;
      .tab-content{
        padding-top: 20px;
        @media screen and (min-width: $lg-breakpoint){
          padding-top: 24px;
        }
        display: none;
        flex-direction: column;
        @media screen and (min-width: $md-breakpoint){
          flex-direction: row;
        }
        &.active{
          display: flex;
          flex-direction: column;
          @media screen and (min-width: $md-breakpoint){
            flex-direction: row;
          }
        }
        .left {
          order: 2;
          @media screen and (min-width: $md-breakpoint){
            order: 1;
          }
        }
        .right {
          order: 1;
          @media screen and (min-width: $md-breakpoint){
            order: 2;
          }
        }
        p{
          margin-top: 0;
          margin-bottom: 16px;
          font-family: $sFont;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 30px;
        }
        .arrow-button{
          margin-top: 24px;
        }
        video{
          margin-top: 24px;
          width: 100%;
          filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.1));
          @media screen and (min-width: $lg-breakpoint){
            margin-top: 0;
          }
        }
      }
    }
    .arrow {
      position: absolute;
      display: inline-block;
      //visibility: hidden;
      width: 25px;
      height: 70px;
      top: 0px;
      padding-top: 14px;
      vertical-align: middle;
      background: red;
      display: block;
      cursor: pointer;
      z-index: 10;
    }
    .prev {
      background: -moz-linear-gradient(right, white 45%, white 100%) no-repeat;
      background: -webkit-linear-gradient(right, white 45%, white 100%) no-repeat;
      background: linear-gradient(to right, white 45%, rgba(255, 255, 255, 0) 100%) no-repeat;
      left: 0;
    }

    .prev:before {
      content: "";
      background-image: url("img/carrot-blue.svgz");
      background-repeat: no-repeat;
      transform: rotate(180deg);
      display: block;
      width: 15px;
      height: 25px;
    }
    .next {
      background: -moz-linear-gradient(right, rgba(255, 255, 255, 0) 0%, white 55%, white 100%) no-repeat;
      background: -webkit-linear-gradient(right, rgba(255, 255, 255, 0) 0%, white 55%, white 100%) no-repeat;
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 55%, white 100%) no-repeat;
      right: 0;
      text-align: right;

    }
    .next:after {
      content: "";
      background-image: url("img/carrot-blue.svgz");
      background-repeat: no-repeat;
      background-position: bottom;
      display: block;
      width: 15px;
      height: 25px;
      margin-left: auto;
    }
  }
}

.pinned-element {
  position: fixed;
  left: 0;
}