@import "base/styles", "collections/styles", "components/styles";

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  position: relative;
}

body {
  padding-top: 90px;
  font-family: $sFont;
  color: $fontColor;
}

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

