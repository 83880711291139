.promo-wrapper {
  .component-promo-banner {
    background: $blue-20;
    padding: 20px 24px;
    display: flex;
    align-items: flex-start;
    flex-flow: column;
    @media screen and (min-width: $md-breakpoint) {
      padding: 12px 24px;
      align-items: center;
      column-gap: 20px;
      display: flex;
      flex-flow: row;
      justify-content: center;
    }
    p {
      color: #fff;
      font-family: $pFontBold;
      font-size: 16px;
      letter-spacing: -.005em;
      line-height: 24px;
    }
    a {
      color: $white;
      font-family: $pFontBold;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}