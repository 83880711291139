@font-face {
  font-family: 'openSans';
  font-display: swap;
  src: url('/src/fonts/open_sans/OpenSans-Regular-webfont.eot');
  src: local('openSans'), local('Open Sans'), url('/src/fonts/open_sans/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('/src/fonts/open_sans/OpenSans-Regular-webfont.woff') format('woff'),
  url('/src/fonts/open_sans/OpenSans-Regular.ttf') format('truetype'),
  url('/src/fonts/open_sans/OpenSans-Regular-webfont.svg#OpenSansRegular') format('svg');
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
}
@font-face {
  font-family: 'openSansLight';
  font-display: swap;
  src: url('/src/fonts/open_sans/OpenSans-Light-webfont.eot');
  src: url('/src/fonts/open_sans/OpenSans-Light-webfont.eot?#iefix') format('embedded-opentype'),
  url('/src/fonts/open_sans/OpenSans-Light-webfont.woff') format('woff'),
  url('/src/fonts/open_sans/OpenSans-Light.ttf') format('truetype'),
  url('/src/fonts/open_sans/OpenSans-Light-webfont.svg#OpenSansRegular') format('svg');
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
}
@font-face {
  font-family: 'openSansBold';
  src: url('/src/fonts/open_sans/OpenSans-Bold-webfont.eot');
  src: url('/src/fonts/open_sans/OpenSans-Bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('/src/fonts/open_sans/OpenSans-Bold-webfont.woff') format('woff'),
  url('/src/fonts/open_sans/OpenSans-Bold.ttf') format('truetype'),
  url('/src/fonts/open_sans/OpenSans-Bold-webfont.svg#OpenSansRegular') format('svg');
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-display: swap;
}

@font-face {
  font-family: 'SF';
  src: url('/src/fonts/salesforce_sans/SalesforceSans-Regular.eot');
  src: url('/src/fonts/salesforce_sans/SalesforceSans-Regular?#iefix') format('embedded-opentype'),
  url('/src/fonts/salesforce_sans/SalesforceSans-Regular.woff') format('woff'),
  url('/src/fonts/salesforce_sans/SalesforceSans-Regular.svg#SalesforceSans-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-display: swap;
}
@font-face {
  font-family: 'SFLight';
  src: url('/src/fonts/salesforce_sans/SalesforceSans-Light.eot');
  src: url('/src/fonts/salesforce_sans/SalesforceSans-Light?#iefix') format('embedded-opentype'),
  url('/src/fonts/salesforce_sans/SalesforceSans-Light.woff') format('woff'),
  url('/src/fonts/salesforce_sans/SalesforceSans-Light.svg#SalesforceSans-Light') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'SFBold';
  src: url('/src/fonts/salesforce_sans/SalesforceSans-Bold.eot');
  src: url('/src/fonts/salesforce_sans/SalesforceSans-Bold.eot?#iefix') format('embedded-opentype'),
  url('/src/fonts/salesforce_sans/SalesforceSans-Bold.woff') format('woff'),
  url('/src/fonts/salesforce_sans/SalesforceSans-Bold.svg#SalesforceSans-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvantGarde';
  src: url('/src/fonts/avant_garde/ITCAvantGardeStd-Demi.otf');
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-display: swap;
}

@font-face {
  font-family: 'AvantGardeBold';
  src: url('/src/fonts/avant_garde/ITCAvantGardeStd-Demi.otf');
  font-weight: 600;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-display: swap;
}