
.page-ambassadors {
  padding-top: 62px;
  p {
    font-size: 15px;
    line-height: 19px;
  }
  .bg-stars {
    .component-banner-f {
      background-image: url(https://developer.mulesoft.com/sites/default/files/Mobile_MainHeader_Background.png);
      background-size: contain;
      @media screen and (min-width: $md-breakpoint) {
        background-image: url(https://developer.mulesoft.com/sites/default/files/MainHeader_Background.png);
        background-position: center;
        background-repeat: no-repeat;
      }
      .banner-content-container {
        h1 {
          color: $white;
        }
      }
    }
  }

  .main-content-ambassadors {
    background: url(https://developer.mulesoft.com/sites/default/files/Mobile_Second_Section_Background.png);
    background-size: contain;
    background-position-y: bottom;
    background-repeat: no-repeat;
    @media screen and (min-width: $sm-breakpoint){
      background: url(https://developer.mulesoft.com/sites/default/files/Tablet_SecondSection_Background.png);
      background-size: contain;
    }
    @media screen and (min-width: $md-breakpoint){
      background: url(https://developer.mulesoft.com/sites/default/files/SecondSection_Background.png);
      background-size: contain;
      background-position: -136px -40px;
    }
    img {
      margin-top: 0px;
      @media screen and (min-width: $md-breakpoint) {
        margin-top: -130px;
      }
    }
  }

  .section-ambassadors {
    .ambassadors-title {
      padding-bottom: 66px;
      text-align: center;
      font-family: $pFont;
      font-size: 26px;
      line-height: 30px;
    }
    .view-footer {
      .ambassador-md-overlay {
        background: rgba(0,0,0,0.5);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        z-index: 10;
        overflow: auto;
        display: none;
        &.modal-opened {
          display: block;
        }
        .ambassador-md {
          display: block;
          position: relative;
          margin: auto;
          background: $white;
          top: 72px;
          z-index: 9;
          margin-bottom: 60px;
          max-width: 90%;
          @media screen and (min-width: $md-breakpoint) {
            max-width: 775px;
          }
          .title-about {
            font-size: 20px;
            font-family: $sFont;
            margin-top: 33px;
            font-style: italic;
            padding-bottom: 10px;
          }
          .meet.no-display {
            display: none;
          }
          .description {
            p {
              font-size: 15px;
              line-height: 19px;
              margin-top: 20px;
              &:last-child {
                margin-bottom: 33px;
              }
            }
          }
          .header-background {
            background-image: linear-gradient(-90deg, $muleBlue 27%, #005295 40%, $darkNavyBlue 95%);
            background: -moz-linear-gradient(left, $darkNavyBlue 0, $muleBlue 100%);
            background: -webkit-linear-gradient(left, $darkNavyBlue 0,$muleBlue 100%);
            .modal-header {
              background: url(/src/img/ambassadors/lightbox_header.png);
              background-size: 100%;
              background-position: center;
              background-repeat: no-repeat;
              h3 {
                text-align: center;
                margin-bottom: 33px;
                font-family: $pFont;
                font-size: 33px;
                padding: 93px 0 49px;
                margin-top: 0;
                color: #fff;
              }
              .modal-close {
                display: inline-block;
                color: #fff;
                position: absolute;
                right: 6px;
                top: 6px;
                cursor: pointer;
                font-size: 35px;
                font-family: $pFont;
                font-weight: 300;
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                transform: rotate(45deg);
                z-index: 20;
                opacity: 1;
              }
            }
          }
          .modal-content {
            padding: 8.33%;
            .top-content {
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              @media screen and (min-width: $md-breakpoint) {
                flex-direction: row;
              }
            }
            @media screen and (min-width: $md-breakpoint) {
              padding: 40px 95px 0;
            }
            .picture {
              position: relative;
              max-width: 269px;
              width: 100%;
              float: none;
              @media screen and (min-width: $md-breakpoint) {
                width: 45%;
              }
              img {
                max-width: 100%;
              }
              &:after {
                content: " ";
                display: block;
                background-image: url(/src/img/ambassadors/MS_AmbassadorsBadge.png);
                background-size: contain;
                height: 98px;
                width: 98px;
                position: absolute;
                right: -11px;
                bottom: 16px;
                border-radius: 50%;
                box-shadow: 0 22px 44px 0 rgba(0,0,0,0.10);
              }
            }
            .content-data {
              width: 100%;
              @media screen and (min-width: $md-breakpoint) {
                width: 45%;
              }
            }
            .names, .years, .city, .country, .position {
              text-align: left;
            }
            .names {
              font-size: 30px;
              font-family: $pFont;
              margin-top: 33px;
              margin-bottom: 13px;
              line-height: 30px;
            }
            .years, .country, .position, .city {
              font-family: $sFont;
              font-size: 20px;
              margin: 0;
              line-height: 25px;
            }
            .position {
              border-top: 2px #d3d3d3 solid;
              margin-top: 30px;
              padding-top: 20px;
              font-style: italic;
            }
            .about {
              width: 100%;
              float: left;
              margin-right: 25px;
              position: relative;
            }
            .social-media {
              text-align: left;
            }
            .certifications {
              text-align: left;
              padding-bottom: 69px;
            }
          }
        }
        &.alumni {
          .ambassador-md {
            .modal-content {
              .picture:after {
                background-image: url(/src/img/ambassadors/alumni-logo.png);
              }
            }
          }
        }
      }
    }
  }

  .section-main-content-2-columns {
    h3 {
      font-size: 20px;
      margin-block: 30px;
      font-family: $pFontBold;
    }
    ul {
      font-size: 15px;
      line-height: 25px;
      color: #5c5c5c;
      margin-top: 30px;
      margin-bottom: 0;
      padding-left: 30px;
      list-style-image: url(/src/img/ambassadors/checkmark-bullet.svg);
      li {
        padding-bottom: 20px;
      }
    }
  }

  .section-main-content-ambassadors {
    text-align: center;
    margin-top: -40px;
    .image {
      img {
        width: 200px;
        height: 200px;
        margin: -10px 20px 38px;
        -webkit-box-shadow: 0 22px 44px 0 rgba(0,0,0,0.10);
        -moz-box-shadow: 0 22px 44px 0 rgba(0,0,0,0.10);
        box-shadow: 0 22px 44px 0 rgba(0,0,0,0.10);
        border-radius: 50%;
      }
    }
    h2 {
      font-family: $pFont;
      font-size: 26px;
      line-height: 30px;
      margin: 12px;
    }
    p {
      font-family: $sFont;
      font-size: 15px;
      line-height: 25px;
      margin: 10px auto 15px;
      width: 100%;
      @media screen and (min-width: $md-breakpoint) {
        width: 40%;
      }
    }
  }
}
