h1, h2, h3, h4, h5, h6 {
  font-family: $pFontBold;
  color: $blue-20;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
}

h1{
  font-size: 32px;
  line-height: 40px;
  .white {
    color: $white;
  }
  .blue {
    color: $muleBlue;
  }
  @media screen and (min-width: $md-breakpoint) {
    font-size: 40px;
    line-height: 48px;
  }
}


h2 {
  font-size: 24px;
  line-height: 32px;
  &.white{
    color: $white;
  }
  @media screen and (min-width: $md-breakpoint) {
    font-size: 32px;
    line-height: 40px;
  }
}

h3 {
  font-size: 20px;
  line-height: 28px;
  &.white{
    color: $white;
  }
  @media screen and (min-width: $md-breakpoint) {
    font-size: 24px;
    line-height: 32px;
  }
}

h4 {
  font-size: 16px;
  line-height: 24px;
  @media screen and (min-width: $md-breakpoint) {
    font-size: 20px;
    line-height: 28px;
  }
}

p {
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: .0012em;
  color: $fontColor;
  margin: 0;
  &.white{
    color: $white;
  }
}
