/* banner section */
.component-banner .row .row-inside {
    padding-bottom: 184px;
}
/* three cards - steps - section */
.three-steps {
    transform: translateY(-37.5%);
    position: relative;
    overflow: hidden;
    @media screen and (min-width: $sm-breakpoint) {
        transform: translateY(-30.5%);
        transform: translateY(-142px);
    }
    @media screen and (min-width: $md-breakpoint) {
        transform: translateY(-40.5%);
    }
    @media screen and (min-width: $lg-breakpoint) {
        transform: translateY(-39.5%);
    }
}
.three-steps:before {
    @media screen and (min-width: $md-breakpoint) {
        background-image:url('/src/img/brand_pattern_desktop_hero.svgz');
        background-repeat: no-repeat;
        display: block;
        content: " ";
        position: absolute;
        width: 1440px;
        height: 593px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        top: -60px;
    }
}
.three-steps .row-inside {
    padding-top: 0px;
    padding-bottom: 30px;
}
.three-steps .steps {
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    padding: 15px 0px;
    background: #fff;
    position: relative;
    z-index: 2;
    @media screen and (min-width: $sm-breakpoint) {
        padding: 33px 0px;
    }
    @media screen and (min-width: $md-breakpoint) {
        padding: 32px 0px 30px 0;
        justify-content: space-evenly;
        display: flex;
        padding-left: 30px;
        padding-right: 30px;
        border-radius: 4px;
    }
    @media screen and (min-width: $lg-breakpoint) {
        justify-content: center;
        padding-left: 0px;
        padding-right: 0px;
        padding: 32px 0px 30px 0;
    }
}
.card {
    text-align: left;
    background-color: $veryLightBlue;
    position: relative;
    background: #fff;
    width: 95.3%;
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    border-radius: 4px;
    cursor: pointer;
    height: 90px;
    padding: 9px 11px;
    @media screen and (min-width: $sm-breakpoint) {
        margin-bottom: 5px;
        margin-top: 5px;
        width: 79.6%;
        font-size: 45px;
        min-height: auto;
        padding: 15px 30px 0px 30px;
        height: 98px;
        justify-content: space-between;
    }
    @media screen and (min-width: $md-breakpoint) {
        width: 21%;
        display: inline-block;
        padding: 21px 25px;
        height: 260px;
        width: 200px;
        background: #E8F8FF;
        margin: initial;
        height: 260px;
    }
    @media screen and (min-width: $lg-breakpoint) {
        margin: 0px;
        width: 21.5%;
    }
    &:first-child {
        @media screen and (min-width: $sm-breakpoint) {

        }
        @media screen and (min-width: $md-breakpoint) {
            margin-left: 0px;
            margin-right: 0px;
            margin-bottom: 0px;
        }
        @media screen and (min-width: $lg-breakpoint) {

        }
        .step-no {
            color: $indigo;

        }
    }
    &:last-child {
        height: 90px;
        margin-top: 0px;
        @media screen and (min-width: $sm-breakpoint) {
            margin-top: 0px;
            margin-bottom: 0px;
        }
        @media screen and (min-width: $md-breakpoint) {
            margin-left: 0px;
            margin-right: 0px;
            height: 260px;
            margin-top: 0px;
        }
        @media screen and (min-width: $lg-breakpoint) {

        }
        .step-no {
            color: #E50097;
        }
    }
    .step-no {
        font-size: 45px;
        line-height: 56px;
        border-radius: 50%;
        width: 70px;
        height: 70px;
        text-align: center;
        vertical-align: middle;
        font-family: $pFontBold;
        color: #9500DB;
        @media screen and (min-width: $sm-breakpoint) {
            display: inline-block;
            background: #E8F8FF;
        }
        @media screen and (min-width: $md-breakpoint) {
            min-width: auto;
            display: block;
            margin-left: 0px;
            margin-right: 0px;
            background: white;
        }
    }
    .text-wrapper {
        min-width: 142px;
        @media screen and (min-width: $sm-breakpoint) {
            display: inline-block;
            min-width: 202px;
        }
        @media screen and (min-width: $md-breakpoint) {
            display: block;
            min-width: auto;
            margin-top: 12px;
        }
    }
    h2,
    h3 {
        font-size: 22px;
        line-height: 32px;
        color: $muleBlue;
        font-family: $pFontBold;
        margin-bottom: 0px;
    }
    p {
        font-size: 16px;
        line-height: 30px;
        margin-top: 0px;
    }
    span.arrow-icon {
        background-image: url('/src/img/arrow.svg');
        width: 27px;
        display: inline-block;
        margin-top: 23px;
        height: 21px;
        @media screen and (min-width: $md-breakpoint) {
            margin-top: 57px;
        }
    }
    &:hover {
        background: #E8F8FF;
        @media screen and (min-width: $md-breakpoint) {
            box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
        }
    }
}
/* dots between cards*/
.dots {
    transform: translateY(-4px);
    font-size: 40px;
    line-height: 0px;
    width: 75px;
    letter-spacing: 8px;
    display: inline-block;
    @media screen and (min-width: $sm-breakpoint) {
        transform: translateY(20px);
        transform: translate(10px, -8px);
    }
    @media screen and (min-width: $md-breakpoint) {
        transform: translateY(calc(50% - 8px));
        letter-spacing: 12px;
        font-size: 40px;
        padding-left: 9px;
        width: 65px;
    }
    @media screen and (min-width: $lg-breakpoint) {
        letter-spacing: 5px;
        letter-spacing: 12px;
        width: 93px;
    }
    &:nth-child(2) {
        @media screen and (min-width: $sm-breakpoint) {
            transform: translate(10px, -5px);
        }
        @media screen and (min-width: $md-breakpoint) {
            transform: translateY(calc(50% - 8px));
        }
    }
}

/* Section: Carousel intro */
.section-carousel-intro .row-inside {
  padding-top: 120px;
  padding-bottom: 0;
  position: relative;
  z-index: 1;

    @media screen and (min-width: $md-breakpoint){
      z-index: 0;
    }
}

.section-carousel-intro .carousel-body {
  font-size: 22px;
  line-height: 32px;
  max-width: 500px;
  margin-top: 7px;
}

/* Section: Carousel */
.section-carousel {
  position: relative;
  max-width: 100%;
  overflow-x: clip;
  z-index: 0;
  background: linear-gradient(180deg, transparent 44%, $cloud-blue-95 10%);

  @media screen and (min-width: $sm-breakpoint){
    background: linear-gradient(180deg, transparent 33%, $cloud-blue-95 10%)
  }
}

.section-carousel .row-inside {
  position:relative;
  padding-top: 21px;
  padding-bottom: 0;
}

.section-carousel .row-inside:before {
    @media screen and (min-width: $md-breakpoint){
        content: '';
        background-image: url(/src/img/brand_pattern_carousel.svgz);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: top right;
        display: block;
        position: absolute;
        width: 900px;
        height: 593px;
        top: -255px;
        right: -155px;
        z-index: -1;
    }
}

.section-news{
    img{
        display: none;
        @media screen and (min-width: $md-breakpoint){
            display: block;
        }
    }
    .row .row-inside {
        padding-top: 0;
        padding-bottom: 40px;
        @media screen and (min-width: $md-breakpoint){
            padding-top: 40px;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        @media screen and (min-width: $lg-breakpoint) {
            padding-top: 60px;
            padding-bottom: 60px;
        }
        .section-news-title{
            margin-top: 0;
            margin-bottom: 0;
            text-align: center;
            @media screen and (min-width: $md-breakpoint) {
                text-align: left;
            }
        }
        .section-news-body{
            margin-top: 8px;
            margin-bottom: 0;
            text-align: center;
            font-size: 22px;
            line-height: 32px;
            @media screen and (min-width: $sm-breakpoint) {
                margin-bottom: 24px;
            }
            @media screen and (min-width: $md-breakpoint) {
                margin-top: 16px;
                margin-bottom: 64px;
                text-align: left;
            }
        }
    }
}

.home-b{
    .component-banner-a .banner-title {
        line-height: 80px;
    }
    .three-steps {
        width: 100%;
        padding-bottom: 50px;
        padding-top: 30px;
        &::before {
            background-image: url("/src/img/dots-home-b.svg");
            background-repeat: no-repeat;
            display: block;
            content: " ";
            position: absolute;
            width: 1440px;
            height: 1000px;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            top: -75px;
        }
        .steps {
            padding: 0;
            .card {
                width: 100%;
                padding: 40px 40px 24px 40px;
                height: auto;
                background: $white;
                &.first {
                    .step-no{
                        color: $indigo;
                    }
                }
                &.middle {
                    .step-no{
                        color: $purple;
                    }
                }
                &.last {
                    .step-no{
                        color: $magenta;
                    }
                }
                .step-no {
                    background: transparent;
                    font-size: 64px;
                    line-height: 56px;
                    font-family: $pFontBold;
                }
                h2 {
                    font-size: 33px;
                    line-height: 44px;
                    color: $black;
                }
                p{
                    font-size: 22px;
                    line-height: 40px;
                    margin-bottom: 40px;
                }
                .cta {
                    border-radius: 5px;
                    width: 100%;
                    height: 48px;
                    line-height: 24px;
                }
            }
        }
    }
}

.home-c {
    .tab-system-section {
        margin-top: 0;
        .row-inside {
            padding-top: 80px;
            .tabs-title {
                text-align: center;
                @media screen and (min-width: $md-breakpoint){
                    text-align: left;
                }
            }
            .tabs-body {
                text-align: center;
                @media screen and (min-width: $md-breakpoint){
                    text-align: left;
                }
            }
        }
    }

    .section-carousel-intro {
        .carousel-title {
            text-align: center;
            @media screen and (min-width: $sm-breakpoint){
                text-align: left;
            }
        }
        .carousel-body {
            text-align: center;
            @media screen and (min-width: $sm-breakpoint){
                text-align: left;
            }
        }
    }

    .component-banner .row .row-inside {
        padding-bottom: 70px;
        @media screen and (min-width: $md-breakpoint){
            padding-bottom: 120px;
        }
    }


}

body.with-promo-banner {
    padding-top: 72px;
    @media screen and (min-width: $md-breakpoint) {
        padding-top: 72px;
    }
}

/* new Home Page 2024 */
/* cards section */
.homepage {
    .component-banner .row {
        .row-inside {
            padding-bottom: initial;
        }
    }
    .cards-section {
        padding-top: 100px;
        @media screen and (min-width: $md-breakpoint) {
            padding-top: 130px;
        }
        .cards-title {
            @media screen and (min-width: $md-breakpoint) {
                font-size: 40px;
                line-height: 48px;
                padding-top: 30px;
            }
            &+p {
                @media screen and (min-width: $md-breakpoint) {
                    font-size: 20px;
                    line-height: 28px;
                }
            }
        }
    }
}
