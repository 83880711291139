$base-font-size: 16px; // The font size set on the root html element.
$base-line-height: 24px; // This line-height determines the basic unit of vertical rhythm.
$site-max-width: rem-calc(1160);

$global-font-size: 100%;
$global-width: 1160px;
$global-lineheight: 1.5;
$body-antialiased: true;
$global-margin: 1rem;
$global-padding: 1rem;
$global-weight-normal: normal;
$global-weight-bold: bold;
$global-radius: 0;
$global-text-direction: ltr;

$padding: 60px;

// The amount lists, blockquotes and comments are indented.
$indent-amount: 30px;

// The height of the navigation container.
$nav-height: 85px;

// Colours
$muleBlue:    #00a0df;
$blue:        #0083cf;
$hoverBlue:   #0083cf;
$white:       #FFFFFF;
$black:       #000000;
$green:       #00B29B;
$darkGrey:    #171717;
$mediumGrey:  #65646a;
$lightGrey:   #F2F2F2;
$secondaryBlue:   #00a1e1;
$backgroundGrey:  #f2f2f2;
$link:          $muleBlue;
$linkHoverBlue: $hoverBlue;
$globalGrey: 	#181818;
$outlineGrey:   #BBBBBB;
$fontColor: 	$globalGrey;
$lightBlue:   #f4fcff;
$veryLightBlue:   #e8f8ff;
$hoverbutton:   #40B4E5;
$sidebarbg:   #f5f6f7;
$tableborder:   #989699;
$tablebg:   #f2f2f2;
$breadcrumbGrey: #99999a;
$marketoborder: #cfcfcf;
$navyBlue: #032D60;
$darkNavyBlue: #032D60;
$lightHoverBlue: #C0EDFF;
$disclaimerFontColor: #5F6972;
$aqua: #00D4D4;
$indigo: #5E66F9;
$purple: #9500DB;
$magenta: #E50097;
$breadcrumbGreyTutorials: #727580;
$eyebrowGrey: #6D7883;
$limeGreen: #BCD85F;
$hoverLightBlue: #C0EDFE;

// Font WES.
$sFont:              "SF", Arial, sans-serif;
$sFontLight:         "SFLight", Arial, sans-serif;
$sFontBold:          "SFBold", Arial, sans-serif;
$pFont:      "AvantGarde", Arial, sans-serif;
$pFontBold:  "AvantGardeBold", Arial, sans-serif;

//WES COLORS
//CLOUD
$cloud-blue-95-light: rgba(234, 245, 254, 0.5);
$cloud-blue-95: #eaf5fe;

//BLUE
$blue-10: #001639;
$blue-20: #032D60;
$blue-50: #0176D3;
$blue-65: #57A3FD;
$blue-95: #EEF4FF;
$muleBlue: $blue-50;

//INDIGO
$indigo-50: #5867E8;

//GREEN
$green-60: #3BA755;
$green-65: #41B658;
$green-90: #cdefc4;

//YELLOW
$yellow-95: #fbf3e0;
$yellow-80: #FCC003;


