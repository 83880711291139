.component-banner-b {
  padding-top: 0;
  padding-bottom: 18px;
  @media screen and (min-width: $md-breakpoint) {
    padding-top: 75px;
  }
  .breadcrumbs {
    text-align: left;
    ul {
      padding: 0px;
      margin-bottom: 8px;
      @media screen and (min-width: $md-breakpoint) {
        //margin-top: 12px;
        margin-top: 24px;
      }
    }
    ul li {
      display: inline-block;
    }
    ul li:not(:last-child):after {
      content: ">";
      display: inline-block;
      color: $blue-20;
      font-size: 12px;
      vertical-align: middle;
      padding-left: 5px;
      padding-top: 1px;
    }
    ul li a,
    ul li span {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: $blue-20;
      font-family: $sFont;
      &:not(span) {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  h2.multicolor-title-inline,
  h1.multicolor-title-inline {
    color: $navyBlue;
    font-size: 33px;
    line-height: 44px;
    text-align: start;
    .light-title {
      color: $muleBlue;
      display: inline-block;
    }
  }
}
