.component-banner-g {
  text-align: center;
  @media screen and (min-width: $md-breakpoint){
    text-align: left;
  }
  .banner-copy {
    .title {
      font-size: 33px;
      line-height: 45px;
      margin-top: 0;
      margin-bottom: 30px;
      @media screen and (min-width: $md-breakpoint) {
        font-size: 48px;
        line-height: 56px
      }
    }
    p {
      font-size: 22px;
      line-height: 32px;
      margin-bottom: 25px;
    }
    .cta-container {
      margin-bottom: 65px;
      @media screen and (min-width: $md-breakpoint) {
        margin-bottom: 75px;
      }
      @media screen and (min-width: $lg-breakpoint) {
        margin-bottom: 0;
      }
    }
  }

  .image-container {
    margin: 0 auto;
    max-width: 485px;
    @media screen and (min-width: $lg-breakpoint) {
      margin: 0 auto;
    }
    img {
      display: inline-block;
      vertical-align: middle;
      max-width: 100%;
      height: auto;
    }
  }

}