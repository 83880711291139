.component-banner-a {
  padding-top: 35px;
  .row {
    
    text-align: center;
    background-position: center 30%;
  }
  .breadcrumbs {
    text-align: center;
    @media screen and (min-width: $md-breakpoint) {
      text-align: left;
    }
    
    ul {
      padding: 0px;
      @media screen and (min-width: $md-breakpoint) {
        margin-top: 12px;
      }
    }
    ul li {
      display: inline-block;
    }
    ul li:not(:last-child):after {
      content: ">";
      display: inline-block;
      color: $muleBlue;
      font-size: 12px;
      vertical-align: middle;
      padding-left: 5px;
      padding-top: 1px;
    }
    ul li a {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: $muleBlue;
    }
  }
  h1 {
    font-size: 33px;
    line-height: 44px;
    @media screen and (min-width: $sm-breakpoint) {
      font-size: 45px;
      line-height: 56px;
    }
    @media screen and (min-width: $md-breakpoint) {
      font-size: 60px;
      line-height: 72px;
    }
  }
  h2.banner-eyebrow {
    color: $white;
    font-size: 14px;
    line-height: 25px;
    text-transform: uppercase;
    font-family: $pFontBold;
    margin-top: 18px;
    letter-spacing: 5px;
    @media screen and (min-width: $md-breakpoint) {
      margin-top: 22px;
    }
    @media screen and (min-width: $lg-breakpoint) {
      margin-top: 30px;
    }

  }
  .banner-title {
    margin: 0;
    margin-top: 5px;
    @media screen and (min-width: $md-breakpoint) {
      margin-top: 15px;
    }
    @media screen and (min-width: $lg-breakpoint) {
      margin-top: 30px;
    }
  }

  .banner-body {
    margin: 0;
  }
  h1.multicolor-block span {
    display: block;
  }
}
