.no-padding-top .row>.row-inside {
  padding-top: 0;
}

.no-padding-bottom .row>.row-inside {
  padding-bottom: 0;
}

.half-padding-top .row>.row-inside {
}

.half-padding-top .row>.row-inside {
  padding-top: $padding / 2;
}

.half-padding-bottom .row>.row-inside {
  padding-bottom: $padding / 2;
}

.quarter-padding-top .row > .row-inside {
  padding-top: $padding / 3;
}

.quarter-padding-bottom .row > .row-inside {
  padding-top: $padding / 3;
}

.no-paddings-region .row > .row-inside {
  padding-bottom: 0;
  padding-top: 0;
}

/****** CTAs ******/
a {
  color: $muleBlue;
  text-decoration: none;
  &:not([class]) {
    font-family: $sFontBold;
    &:hover {
      color: $darkNavyBlue;
      text-decoration: underline;
      text-decoration-thickness: 2px;
      text-underline-offset: 3px;
    }
  }
}

.page-tutorial li img {
  width: 100%;
}

.centered {
  text-align: center;
}

.centered-img img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}