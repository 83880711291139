.component-banner-c {
  padding-top: 20px;
  .row {
    text-align: center;
    background-position: center 30%;
  }
  .breadcrumbs {
    text-align: center;
    @media screen and (min-width: $md-breakpoint) {
      text-align: left;
    }
    
    ul {
      padding: 0px;
      @media screen and (min-width: $md-breakpoint) {
        margin-top: 12px;
      }
    }
    ul li {
      display: inline-block;
    }
    ul li:not(:last-child):after {
      content: ">";
      display: inline-block;
      color: $muleBlue;
      font-size: 12px;
      vertical-align: middle;
      padding-left: 5px;
      padding-top: 1px;
    }
    ul li a {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: $muleBlue;
    }
  }
  h1 {
    font-size: 33px;
    line-height: 44px;
    @media screen and (min-width: $sm-breakpoint) {
      font-size: 45px;
      line-height: 56px;
    }
  }
  p.banner-eyebrow {
    /*color: #6D7883;
    font-size: 14px;
    line-height: 25px;
    text-transform: uppercase;
    font-family: $pFontBold;
    margin-top: 18px;
    letter-spacing: 5px;*/
    letter-spacing: initial;
    margin-bottom: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-transform: none;
    font-family: $sFont;
    color: $blue-20;
    @media screen and (min-width: $md-breakpoint) {
      margin-top: 95px;
    }
    @media screen and (min-width: $lg-breakpoint) {
      /*margin-top: 30px;*/
    }

  }
  .banner-title {
    margin: 0;
    margin-top: 5px;
    margin-bottom: 24px;
    @media screen and (min-width: $md-breakpoint) {
      margin-top: 15px;
    }
    @media screen and (min-width: $lg-breakpoint) {
      /*margin-top: 30px;
      margin-bottom: 50px;*/
    }
  }

  .banner-body {
    margin: 0;
  }
  h1.multicolor-block span {
    display: block;
  }
  .navy-blue{
    color: $navyBlue;
  }
  p{
    width: 100%;
    margin: 0 auto;
    font-size: 22px;
    line-height: 40px;
    margin-bottom: 24px;
    @media screen and (min-width: $sm-breakpoint) {
      width: 83.33%
    }
    @media screen and (min-width: $md-breakpoint) {
      width: 50%
    }
  }
  .cta {
    margin: 0 auto;
    width: 100%;
    @media screen and (min-width: $sm-breakpoint) {
      width: 200px;
    }
  }

  .row-inside:before {
    @media screen and (min-width: $md-breakpoint) {
      background-image: url("/content/media/image_statsHuman.png");
      background-repeat: no-repeat;
      background-size: 100%;
      display: block;
      content: " ";
      position: absolute;
      width: 634px;
      height: 456px;
      top: 128px;
      left: 75%;
    }
    @media screen and (min-width: $lg-breakpoint) {
      background-image: url("/content/media/image_tubes.png");
      background-repeat: no-repeat;
      background-size: 100%;
      display: block;
      content: " ";
      position: absolute;
      width: 746px;
      height: 456px;
      top: 134px;
      margin-left: -450px;
      left: initial;
    }
  }

  .row-inside:after{
    @media screen and (min-width: $md-breakpoint) {
      background-image: url("/content/media/image_tubes.png");
      background-repeat: no-repeat;
      background-size: 100%;
      display: block;
      content: " ";
      position: absolute;
      width: 746px;
      height: 456px;
      top: 128px;
      right: 75%;
    }
    @media screen and (min-width: $lg-breakpoint) {
      background-image: url("/content/media/image_statsHuman.png");
      background-repeat: no-repeat;
      background-size: 100%;
      display: block;
      content: " ";
      position: absolute;
      width: 634px;
      height: 456px;
      top: 120px;
      margin-left: 867px;
      right: initial;
    }
  }
}

body.with-promo-banner {
  .component-banner-c.component-banner {
    position: relative;
    .row-inside{
      &::before {
        @media screen and (min-width: $md-breakpoint) {
          top:28px;
        }
        @media screen and (min-width: $lg-breakpoint) {
          top:40px;
        }
      }
      &::after {
        @media screen and (min-width: $md-breakpoint) {
          top:44px;
        }
        @media screen and (min-width: $lg-breakpoint) {
          top:20px;
        }
      }
    }
  }
}
