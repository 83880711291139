.not-found {
  h2 {
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 8px;
  }
  p {
    font-size: 16px;
    margin-bottom: 16px;
  }
  a {
    font-family: $sFontBold;
    color: #0176d3;
    &:hover {
      text-decoration: underline;
      text-decoration-thickness: 2px;
      text-underline-offset: 3px;
      color: #032d60;
    }
  }

}