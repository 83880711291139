/*
   Mobile First Grid

   col-lg-X : large (desktop);
   col-md-X : medium (tablet);
   col-sm-X : small (mobile);
   col-xs-X : extra small (small mobile);

   Example
   .col-lg-3 .col-md-6 .col-sm-12 .col-xs-12
*/

/* Breakpoints */
$sm-breakpoint: 600px;
$md-breakpoint: 992px;
$lg-breakpoint: 1160px;
$xl-breakpoint: 1775px;

/* Paddings */
$xs-vertical-padding: 60px;
$sm-vertical-padding: 60px;
$md-vertical-padding: 60px;
$lg-vertical-padding: 60px;
$xl-vertical-padding: 60px;

/* Range: 0 to $sm-breakpoint-1 */
$xs-gutter: calc(8px / 2);
$xs-margin: calc(16px - #{$xs-gutter});

/* Range: $sm-breakpoint to $md-breakpoint-1 */
$sm-gutter: calc(16px / 2);
$sm-margin: calc(32px - #{$sm-gutter});

/* Range: $md-breakpoint to $lg-breakpoint-1*/
$md-gutter: calc(24px / 2);
$md-margin: calc(16px - #{$md-gutter});

/* Range: $lg-breakpoint to xl-breakpoint */
$lg-gutter: calc(24px / 2);
$lg-margin: calc(16px - #{$lg-gutter});

/* Range: $xl-breakpoint to infinity */
$xl-gutter: calc(24px / 2);
$xl-margin: calc(16px - #{$xl-gutter});


.row {
    margin: 0;
    padding: 0;
}

.row > .row-inside {
    margin: 0 auto;
    font-size: 0;
}

.row.centered,
div[class*=' col-'].centered{
    text-align: center;
}

.row.no-padding-bottom-row > .row-inside {
    padding-bottom: 0;
}

.row.no-padding-top-row > .row-inside {
    padding-top: 0;
}

.row.half-padding-top-row > .row-inside {
    padding-top: calc(#{$xs-vertical-padding} / 2);
}

.row.half-padding-bottom-row > .row-inside {
    padding-bottom: calc(#{$xs-vertical-padding} / 2);
}

.row.white-row > .row-inside {
    background: white;
}

/* Grid range: 0 - 599 */
.row-inside {
    padding: $xs-vertical-padding $xs-margin;
}

.row div[class^='col-'],
.row div[class*=' col-'] {
    display: inline-block;
    padding: 0 $xs-gutter;
    vertical-align: top;
    font-size: 16px;
}

.row .col-xs-1 {
    width: 8.33333333%;
}
.row .col-xs-2 {
    width: 16.66666667%;
}
.row .col-xs-3 {
    width: 25%;
}
.row .col-xs-4 {
    width: 33.33333333%;
}
.row .col-xs-5 {
    width: 41.66666667%;
}
.row .col-xs-6 {
    width: 50%;
}
.row .col-xs-7 {
    width: 58.33333333%;
}
.row .col-xs-8 {
    width: 66.66666667%;
}
.row .col-xs-9 {
    width: 75%;
}
.row .col-xs-10 {
    width: 83.33333333%;
}
.row .col-xs-11 {
    width: 91.66666667%;
}
.row .col-xs-12 {
    width: 100%;
}

/* Shifts */
.row .col-xs-sh-0 {
    margin-left: 0%;
}
.row .col-xs-sh-1 {
    margin-left: 8.33333333%;
}
.row .col-xs-sh-2 {
    margin-left: 16.66666667%;
}
.row .col-xs-sh-3 {
    margin-left: 25%;
}
.row .col-xs-sh-4 {
    margin-left: 33.33333333%;
}
.row .col-xs-sh-5 {
    margin-left: 41.66666667%;
}
.row .col-xs-sh-6 {
    margin-left: 50%;
}
.row .col-xs-sh-7 {
    margin-left: 58.33333333%;
}
.row .col-xs-sh-8 {
    margin-left: 66.66666667%;
}
.row .col-xs-sh-9 {
    margin-left: 75%;
}
.row .col-xs-sh-10 {
    margin-left: 83.33333333%;
}
.row .col-xs-sh-11 {
    margin-left: 91.66666667%;
}
.row .col-xs-sh-12 {
    margin-left: 100%;
}

/* Range: 600 - 991 */
@media screen and (min-width: $sm-breakpoint){
  .row-inside {
      padding: $sm-vertical-padding $sm-margin;
  }
  .row div[class^='col-'],
  .row div[class*=' col-'] {
      padding: 0 $sm-gutter;
  }
  .row .col-sm-1 {
      width: 8.33333333%;
  }
  .row .col-sm-2 {
      width: 16.66666667%;
  }
  .row .col-sm-3 {
      width: 25%;
  }
  .row .col-sm-4 {
      width: 33.33333333%;
  }
  .row .col-sm-5 {
      width: 41.66666667%;
  }
  .row .col-sm-6 {
      width: 50%;
  }
  .row .col-sm-7 {
      width: 58.33333333%;
  }
  .row .col-sm-8 {
      width: 66.66666667%;
  }
  .row .col-sm-9 {
      width: 75%;
  }
  .row .col-sm-10 {
      width: 83.33333333%;
  }
  .row .col-sm-11 {
      width: 91.66666667%;
  }
  .row .col-sm-12 {
      width: 100%;
  }

  /* Shifts */
  .row .col-sm-sh-0 {
      margin-left: 0%;
  }
  .row .col-sm-sh-1 {
      margin-left: 8.33333333%;
  }
  .row .col-sm-sh-2 {
      margin-left: 16.66666667%;
  }
  .row .col-sm-sh-3 {
      margin-left: 25%;
  }
  .row .col-sm-sh-4 {
      margin-left: 33.33333333%;
  }
  .row .col-sm-sh-5 {
      margin-left: 41.66666667%;
  }
  .row .col-sm-sh-6 {
      margin-left: 50%;
  }
  .row .col-sm-sh-7 {
      margin-left: 58.33333333%;
  }
  .row .col-sm-sh-8 {
      margin-left: 66.66666667%;
  }
  .row .col-sm-sh-9 {
      margin-left: 75%;
  }
  .row .col-sm-sh-10 {
      margin-left: 83.33333333%;
  }
  .row .col-sm-sh-11 {
      margin-left: 91.66666667%;
  }
  .row .col-sm-sh-12 {
      margin-left: 100%;
  }
}

/* Range: 992 - 1159 */
@media screen and (min-width: $md-breakpoint) {
  .row-inside {
      padding: $md-vertical-padding $md-margin;
  }
  .row div[class^='col-'],
  .row div[class*=' col-'] {
      padding: 0 $md-gutter;
  }

  .row .col-md-1 {
      width: 8.33333333%;
  }
  .row .col-md-2 {
      width: 16.66666667%;
  }
  .row .col-md-3 {
      width: 25%;
  }
  .row .col-md-4 {
      width: 33.33333333%;
  }
  .row .col-md-5 {
      width: 41.66666667%;
  }
  .row .col-md-6 {
      width: 50%;
  }
  .row .col-md-7 {
      width: 58.33333333%;
  }
  .row .col-md-8 {
      width: 66.66666667%;
  }
  .row .col-md-9 {
      width: 75%;
  }
  .row .col-md-10 {
      width: 83.33333333%;
  }
  .row .col-md-11 {
      width: 91.66666667%;
  }
  .row .col-md-12 {
      width: 100%;
  }

  /* Shifts */
  .row .col-md-sh-0 {
      margin-left: 0%;
  }
  .row .col-md-sh-1 {
      margin-left: 8.33333333%;
  }
  .row .col-md-sh-2 {
      margin-left: 16.66666667%;
  }
  .row .col-md-sh-3 {
      margin-left: 25%;
  }
  .row .col-md-sh-4 {
      margin-left: 33.33333333%;
  }
  .row .col-md-sh-5 {
      margin-left: 41.66666667%;
  }
  .row .col-md-sh-6 {
      margin-left: 50%;
  }
  .row .col-md-sh-7 {
      margin-left: 58.33333333%;
  }
  .row .col-md-sh-8 {
      margin-left: 66.66666667%;
  }
  .row .col-md-sh-9 {
      margin-left: 75%;
  }
  .row .col-md-sh-10 {
      margin-left: 83.33333333%;
  }
  .row .col-md-sh-11 {
      margin-left: 91.66666667%;
  }
  .row .col-md-sh-12 {
      margin-left: 100%;
  }
}

/* Range: 1160+ */
@media screen and (min-width: $lg-breakpoint){
  .row-inside {
      padding: $lg-vertical-padding $lg-margin;
      max-width: 1160px;
  }

  .row div[class^='col-'],
  .row div[class*=' col-'] {
      padding: 0 $lg-gutter;
  }

  .row .col-lg-1 {
      width: 8.33333333%;
  }
  .row .col-lg-2 {
      width: 16.66666667%;
  }
  .row .col-lg-3 {
      width: 25%;
  }
  .row .col-lg-4 {
      width: 33.33333333%;
  }
  .row .col-lg-5 {
      width: 41.66666667%;
  }
  .row .col-lg-6 {
      width: 50%;
  }
  .row .col-lg-7 {
      width: 58.33333333%;
  }
  .row .col-lg-8 {
      width: 66.66666667%;
  }
  .row .col-lg-9 {
      width: 75%;
  }
  .row .col-lg-10 {
      width: 83.33333333%;
  }
  .row .col-lg-11 {
      width: 91.66666667%;
  }
  .row .col-lg-12 {
      width: 100%;
  }

  /* Desktop Shifts */
  .row .col-lg-sh-0 {
      margin-left: 0%;
  }
  .row .col-lg-sh-1 {
      margin-left: 8.33333333%;
  }
  .row .col-lg-sh-2 {
      margin-left: 16.66666667%;
  }
  .row .col-lg-sh-3 {
      margin-left: 25%;
  }
  .row .col-lg-sh-4 {
      margin-left: 33.33333333%;
  }
  .row .col-lg-sh-5 {
      margin-left: 41.66666667%;
  }
  .row .col-lg-sh-6 {
      margin-left: 50%;
  }
  .row .col-lg-sh-7 {
      margin-left: 58.33333333%;
  }
  .row .col-lg-sh-8 {
      margin-left: 66.66666667%;
  }
  .row .col-lg-sh-9 {
      margin-left: 75%;
  }
  .row .col-lg-sh-10 {
      margin-left: 83.33333333%;
  }
  .row .col-lg-sh-11 {
      margin-left: 91.66666667%;
  }
  .row .col-lg-sh-12 {
      margin-left: 100%;
  }
}

/* Range: 1800+ */
@media screen and (min-width: $xl-breakpoint){
  .row-inside {
    padding: $xl-vertical-padding $xl-margin;
    max-width: 1160px;
  }

  .row div[class^='col-'],
  .row div[class*=' col-'] {
    padding: 0 $xl-gutter;
  }

  .row .col-xl-1 {
    width: 8.33333333%;
  }
  .row .col-xl-2 {
    width: 16.66666667%;
  }
  .row .col-xl-3 {
    width: 25%;
  }
  .row .col-xl-4 {
    width: 33.33333333%;
  }
  .row .col-xl-5 {
    width: 41.66666667%;
  }
  .row .col-xl-6 {
    width: 50%;
  }
  .row .col-xl-7 {
    width: 58.33333333%;
  }
  .row .col-xl-8 {
    width: 66.66666667%;
  }
  .row .col-xl-9 {
    width: 75%;
  }
  .row .col-xl-10 {
    width: 83.33333333%;
  }
  .row .col-xl-11 {
    width: 91.66666667%;
  }
  .row .col-xl-12 {
    width: 100%;
  }

  /* Desktop Shifts */
  .row .col-xl-sh-0 {
    margin-left: 0%;
  }
  .row .col-xl-sh-1 {
    margin-left: 8.33333333%;
  }
  .row .col-xl-sh-2 {
    margin-left: 16.66666667%;
  }
  .row .col-xl-sh-3 {
    margin-left: 25%;
  }
  .row .col-xl-sh-4 {
    margin-left: 33.33333333%;
  }
  .row .col-xl-sh-5 {
    margin-left: 41.66666667%;
  }
  .row .col-xl-sh-6 {
    margin-left: 50%;
  }
  .row .col-xl-sh-7 {
    margin-left: 58.33333333%;
  }
  .row .col-xl-sh-8 {
    margin-left: 66.66666667%;
  }
  .row .col-xl-sh-9 {
    margin-left: 75%;
  }
  .row .col-xl-sh-10 {
    margin-left: 83.33333333%;
  }
  .row .col-xl-sh-11 {
    margin-left: 91.66666667%;
  }
  .row .col-xl-sh-12 {
    margin-left: 100%;
  }
}

@media screen and (min-width: $lg-breakpoint) {
  .flex {
    display: flex;
    align-items: center;
  }
}