.page-tutorial,
.tutorials-lp{
  padding-top: 100px;
  @media screen and (min-width: $md-breakpoint) {
    padding-top: 90px;
  }
  .ms-com-content.ms-com-content-header.br-nav.closed.new-nav.with-helmet.no-selector {
    min-height: 99px;
    @media screen and (min-width: $md-breakpoint) {
      height: 93px;
      min-height: auto;
    }
  }
  .reader-bar {
    display: none;
    @media screen and (min-width: $md-breakpoint) {
      background: #E9F2F5;
      position: fixed;
      top: 100px;
      height: 4px;
      width: 100%;
      display: block;
      z-index: 1;
      #readingProgressbar {
        --scrollAmount: 0%;
        background: #BCD85F;
        width: var(--scrollAmount);
        height: 4px;
      }
    }
  }
  .sidebar{
    background: $darkNavyBlue;
    color: $white;
    position: unset;
    width: 100%;
    transition: 0.2s;
    z-index: 16;
    @media screen and (min-width: $md-breakpoint){
      background: $cloud-blue-95;
      width: 25px;
      overflow: hidden;
      margin-top: 4px;
      position: fixed;
      height: 100%;
      z-index: 1;
    }
    &.active-sidebar {
      @media screen and (min-width: $md-breakpoint){
        width: 300px;
      }
      .row > .row-inside {
        .menu-header{
          justify-content: space-between;
          @media screen and (min-width: $md-breakpoint){
            left: 300px;
          }
          .collapse-menu {
            &::before {
              //background-image: url(/src/img/close-btn.svg);
              //height: 15px;
              @media screen and (min-width: $md-breakpoint) {
                display: block;
                padding: 0;
                background-image: url(/src/img/menu-carrot-blue.svg);
                height: 8px;
              }
            }
            @media screen and (min-width: $md-breakpoint) {
              transform: rotate(90deg);
              //transition: 0.2s;
              padding: 15px 5px;
            }
          }
        }
      }
    }
    &.pinned-element {
      position: fixed;
      .row > .row-inside {
        .menu-header .mulesoft-logo {
          display: inline-block;
        }
      }
      &.active-sidebar .row > .row-inside .menu-header .mulesoft-logo {
        display: none;
      }
    }
    .row > .row-inside {
      padding-top: 0;
      padding-bottom: 0;
      .menu-header{
        height: 60px;
        transition: 0.2s;
        display: flex;
        padding: 15px 24px 15px 0;
        align-items: center;
        @media screen and (min-width: $md-breakpoint){
          float: right;
          left: 25px;
          position: fixed;
          height: 48px;
          margin-top: 38px;
          width: 24px;
          background: $veryLightBlue;
          box-shadow: 2px 1px 3px rgba(0, 0, 0, 0.05);
          border-radius: 0 20% 20% 0;
          padding: 0;
        }
        .mulesoft-logo{
          display: none;
          margin-right: 13px;
          @media screen and (min-width: $md-breakpoint) {
            display: none;
            margin-right: 0;
          }
        }
        .menu-title {
          font-family: $sFont;
          font-weight: bold;
          font-size: 16px;
          display: inline-block;
          vertical-align: middle;
          @media screen and (min-width: $md-breakpoint){
            display: none;
          }
        }
        .collapse-menu {
          display: inline-block;
          padding-left: 15px;
          @media screen and (min-width: $md-breakpoint){
            transform: rotate(270deg);
            padding: 15px 5px;
          }
          &::before {
            content: ' ';
            background-image: url('/src/img/menu-carrot.svg');
            background-repeat: no-repeat;
            vertical-align: middle;
            width: 15px;
            height: 10px;
            display: inline-block;
            @media screen and (min-width: $md-breakpoint){
              background-image: url('/src/img/menu-carrot-blue.svg');
              width: 12px;
              height: 8px;
            }
          }
        }
      }
      #menu-tutorials {
        //display: none;
        padding-bottom: 100px;
        @media screen and (min-width: $md-breakpoint){
          float: left;
          display: block;
          //padding: 0 10px;
          width: 280px;
        }
        &.active-menu {
          //display: none;
          background: transparent;
          width: 100%;
          &.mobile {
            display: block;
          }
          @media screen and (min-width: $md-breakpoint){
            padding-bottom: 24px;
            display: block;
          }
        }
        .nav-cta{
          display: block;
          margin-top: 20px;
          cursor: pointer;
          a.cta.solid.indigo:focus-visible{
            outline: 2px solid orange;
          }
          .cta {
            width: 100%;
            @media screen and (min-width: $sm-breakpoint) {
              width: 250px;
            }
          }
        }
        .menu-wrapper {
          margin: 0;
          padding: 32px 0 0 0;
          @media screen and (min-width: $md-breakpoint){
            margin-top: 24px;
            margin-bottom: 0;
            padding-top: 0;
            padding-left: 10px;
          }
          .menu-serie {
            list-style: none;
            overflow-y: hidden;
            max-height: 65vh;
            &:hover {
              overflow-y: scroll;
            }
            .serie-item {
              font-family: $pFontBold;
              line-height: 24px;
              font-size: 16px;
              color: $white;
              vertical-align: middle;
              //transition: 0.4s;
              cursor: pointer;
              text-align: left;
              //display: grid !important;
              grid-template-columns: 45px 1fr;
              &.active {
                &::before {
                  //padding-right: 8px;
                  transform: rotate(0deg);
                  vertical-align: middle;
                  margin-right: 8px;
                  margin-bottom: 3px;
                }
                + .items-wrapper {
                  margin-bottom: 40px;
                }
              }
              @media screen and (min-width: $md-breakpoint) {
                //grid-template-columns: 20px 1fr;
                color: $globalGrey;
                &.active {
                  + .items-wrapper {
                    //margin: 16px 0 8px 15px;

                    .menu-item {
                      //margin-bottom: 16px;
                      margin-bottom: 0;

                      .menu-item:last-child {
                        margin-bottom: 0;
                      }
                    }
                  }

                  &::before {
                    transform: rotate(0deg);
                    padding-right: 0;
                    vertical-align: middle;
                    margin-right: 8px;
                    margin-bottom: 3px;
                  }
                }
              }

              &::before {
                content: ' ';
                background-image: url('/src/img/menu-carrot.svg');
                background-repeat: no-repeat;
                vertical-align: middle;
                width: 15px;
                height: 10px;
                display: inline-block;
                //margin-top: 8px;
                //transform: rotate(270deg);
                //padding-right: 0;
                margin-right: 8px;
                //margin-bottom: 3px;

                @media screen and (min-width: $md-breakpoint) {
                  background-image: url('/src/img/menu-carrot-blue.svg');
                  width: 12px;
                  height: 8px;
                }
              }
            }
            .items-wrapper {
              padding-top: 24px;
              padding-left: 20px;
              max-height: 0;
              overflow: hidden;
              transition: max-height 0.2s ease-out;
              @media screen and (min-width: $md-breakpoint) {
                padding: 0;
              }
              .menu-item {
                list-style: none;
                font-family: $sFont;
                font-size: 14px;
                line-height: 20px;
                //padding: 8px 16px 8px 50px;
                cursor: pointer;
                a {
                  color: $white;
                  font-family: $sFont;
                  &:active {
                    color: $muleBlue;
                    font-weight: bold;
                  }
                  &:hover {
                    text-decoration: none;
                  }
                }
                &.current a {
                  color: $muleBlue;
                  font-weight: bold;
                }
                @media screen and (min-width: $md-breakpoint) {
                  color: $black;
                  margin-left: 8px;
                  padding: 8px 16px;
                  &.current a {
                    color: $muleBlue;
                    font-weight: bold;
                  }
                  &:hover {
                    /*background: $hoverLightBlue;
                    border-radius: 10px;
                    a {
                      color: $muleBlue;
                      font-weight: bold;
                    }*/
                  }
                  a {
                    color: $globalGrey;
                    font-size: 14px;

                    &:active {
                      color: $muleBlue;
                      font-weight: bold;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .component-banner.component-banner-tutorials{
    .row > .row-inside {
      padding-top: 0;
      padding-bottom: 30px;
      @media screen and (min-width: $md-breakpoint) {
        padding-top: 0;
      }
    }
    .breadcrumbs {
      text-align: center;
      @media screen and (min-width: $md-breakpoint) {
        text-align: left;
      }
      ul {
        padding: 0px;
        margin-bottom: 24px;
        margin-top: 24px;
        @media screen and (min-width: $md-breakpoint) {
          margin-top: 16px;
          margin-bottom: 41px;
        }
      }
      ul li {
        display: inline-block;
      }
      ul li:not(:last-child):after {
        content: ">";
        display: inline-block;
        color: $blue-20;
        font-size: 12px;
        vertical-align: middle;
        padding-left: 5px;
        padding-top: 1px;
      }
      ul li a {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: $blue-20;
        font-family: $sFont;
      }
      ul li {
        &:last-child {
          a {
            pointer-events: none;
          }
        }
      }
    }
    .banner-eyebrow {
      font-size: 14px;
      text-align: center;
      letter-spacing: initial;
      margin-bottom: 8px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-transform: none;
      font-family: $sFont;
      color: $blue-20;
      @media screen and (min-width: $md-breakpoint) {
        text-align: left;
        margin-bottom: 14px;
      }
    }
    .banner-title {
      font-family: $pFontBold;
      font-size: 33px;
      line-height: 44px;
      text-align: center;
      margin-bottom: 35px;
      @media screen and (min-width: $md-breakpoint) {
        text-align: left;
        font-size: 45px;
        line-height: 55px;
        margin-bottom:  27px;
      }
    }
    .tutorials-image {
      margin-bottom: 24px;
      img {
        max-width: 100%;
      }
    }
    .image-wrapper {
      @media screen and (min-width: $sm-breakpoint){
        display: flex;
        flex-direction: column-reverse;
      }
      .time {
        font-family: $sFontBold;
        margin-bottom: 16px;
        @media screen and (min-width: $sm-breakpoint){
          float: left;
        }
        &::before {
          display: inline-block;
          background-image: url("/src/img/clock.svg");
          background-repeat: no-repeat;
          content: " ";
          vertical-align: middle;
          width: 18px;
          height: 18px;
          padding-right: 8px;
        }
      }
      .question-container {
        @media screen and (min-width: $sm-breakpoint){
          float: right;
        }
      }
    }
  }
  .tutorials-content{
    h2 {
      font-family: $pFontBold;
      font-size: 22px;
      padding-bottom: 25px;
      @media screen and (min-width: $md-breakpoint){
        font-size: 33px;
        line-height: 43px;
      }
    }
    p {
      font-family: $sFont;
      line-height: 30px;
      font-size: 16px;
      padding-bottom: 40px;
      img {
        max-width: 100%;
        display:block;
        margin:0 auto;
        border-radius: 8px;
      }
    }
    li, ol {
      font-family: $sFont;
      line-height: 30px;
      font-size: 16px;
    }
    [class*="vidyard-inner"] {
      iframe {
        height: 100%;
      }
    }
    iframe {
      width: 100%;
      height: 225px;
      border-radius: 8px;
      @media screen and (min-width: $sm-breakpoint) {
        height: 441px;
      }
    }
    .note {
      background: $blue-95;
      border-left: 4px solid $blue-65;
      padding: 22px 25px;
      margin-bottom: 40px;
      border-radius: 8px;
      a {
        color: #0176D3;
        font-family: $sFontBold;
        &:hover {
          text-decoration: underline;
        }
      }
      &::before {
        content: 'Note';
        font-family: $sFontBold;
        line-height: 30px;
        display: block;
        background-image: url("/src/img/note.svg");
        background-repeat: no-repeat;
        background-position-y: center;
        height: 30px;
        vertical-align: top;
        padding-left: 25px;
        margin-bottom: 10px
      }
      .highlighter-rouge {
        background-color: #D4E3FF;
      }
    }
    .important {
      background: #FEF1EE;
      border-left: 4px solid #EA001E;
      padding: 22px 25px;
      margin-bottom: 40px;
      a {
        color: #EA001E;
        font-family: $sFontBold;
        &:hover {
          text-decoration: underline;
        }
      }
      &::before {
        content: 'Important';
        font-family: $sFontBold;
        line-height: 30px;
        display: block;
        background-image: url("/src/img/important.svg");
        background-repeat: no-repeat;
        background-position-y: center;
        height: 30px;
        vertical-align: top;
        padding-left: 25px;
        margin-bottom: 10px
      }
      .highlighter-rouge {
        background-color: #FFD2D8;
      }
    }
    .important-yellow {
      background: $yellow-95;
      border-left: 4px solid $yellow-80;
      padding: 22px 25px;
      margin-bottom: 40px;
      border-radius: 8px;
      a {
        color: #CA8501;
        font-family: $sFontBold;
        &:hover {
          text-decoration: underline;
        }
      }
      &::before {
        content: 'Important';
        font-family: $sFontBold;
        line-height: 30px;
        display: block;
        background-image: url("/src/img/important-yellow.svg");
        background-repeat: no-repeat;
        background-position-y: center;
        height: 30px;
        vertical-align: top;
        padding-left: 25px;
        margin-bottom: 10px
      }
      .highlighter-rouge {
        background-color: #FFE3A2;
      }
    }
    .tip {
      background: $green-90;
      border-left: 4px solid $green-60;
      padding: 22px 25px;
      margin-bottom: 40px;
      border-radius: 8px;
      a {
        color: #2E844A;
        font-family: $sFontBold;
        &:hover {
          text-decoration: underline;
        }
      }
      &::before {
        content: 'Tip';
        font-family: $sFontBold;
        line-height: 30px;
        display: block;
        background-image: url("/src/img/tip.svg");
        background-repeat: no-repeat;
        background-position-y: center;
        height: 30px;
        vertical-align: top;
        padding-left: 25px;
        margin-bottom: 10px
      }
      .highlighter-rouge {
        background-color: #A7EFA5;
      }
    }
    p + ul {
      margin-top: -30px;
    }
    p + ol {
      margin-top: -30px;
    }
    h2 + ul {
      margin-top: 0;
    }
    h2 + ol {
      margin-top: 0;
    }
  }

  .buttons-wrapper {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    @media screen and (min-width: $sm-breakpoint){
      flex-direction: row;
      justify-content: space-evenly;
      flex-wrap: wrap;
    }
    .cta {
      width: auto;
      min-width: 180px;
      flex-shrink: 0;
      margin-bottom: 22px;
      margin-right: 5px;
      @media screen and (min-width: $md-breakpoint){
        margin-bottom: 0;
      }
    }
  }
  .buttons-paginator {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @media screen and (min-width: $sm-breakpoint){
      flex-direction: row;
    }
    .cta {
      flex-shrink: 0;
      height: 44px;
      width: 100%;
      @media screen and (min-width: $sm-breakpoint){
        width: auto;
        min-width: 200px;
        flex-shrink: initial;
      }
      &:last-child {
        margin-top: 15px;
        @media screen and (min-width: $sm-breakpoint){
          margin-top: 0;
        }
      }
    }
    &.right {
      justify-content: right;
    }
  }



  .no-feedback-widget .m-helpful {
    display: none;
  }

  .m-helpful-lib {
    font-family: inherit;
    margin-left: auto;
    margin-right: auto;
  }

  .m-helpful-lib .question-container > * {
    vertical-align: middle;
    display: inline-block;
  }

  .m-helpful-lib .question {
    margin-right: 7px;
  }

  .m-helpful-lib .question-button {
    display: inline-block;
    text-decoration: none;
    background: $blue-50;
    border-radius: 50%;
    margin: 0 2px;
    width: 16px;
    height: 16px;
  }

  .m-helpful-lib .question-yes::before {
    display: inline-block;
    background-image: url("/src/img/yes.svg");
    background-repeat: no-repeat;
    content: " ";
    vertical-align: middle;
    width: 10px;
    height: 7px;
    margin: 0 3px 11px 3px;
  }

  .m-helpful-lib .question-no::before {
    display: inline-block;
    background-image: url("/src/img/no.svg");
    background-repeat: no-repeat;
    content: " ";
    vertical-align: middle;
    width: 10px;
    height: 7px;
    margin: 0 3px 11px 4px;
  }

  .m-helpful-lib .heart-wrapper {
    display: inline-block;
    vertical-align: middle;
    background: #5f6bf5;
    width: 16px;
    height: 16px;
    padding: 4px 0 0 0;
    border-radius: 50%;
    margin-left: 10px;
    text-align: center;
    font-size: 0;
    margin-bottom: 3px;
  }

  .m-helpful-lib .heart {
    display: inline-block;
    position: relative;
    width: 10px;
    height: 10px;
    background-color: white;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg) scale(0.5);
  }

  .m-helpful-lib .heart:before{
    position: absolute;
    bottom: 0px;
    left: -5px;
    width: 10px;
    height: 10px;
    content: '';
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    background-color: inherit;
  }

  .m-helpful-lib .heart:after{
    position: absolute;
    top: -5px;
    right: 0px;
    width: 10px;
    height: 10px;
    content: '';

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    background-color: inherit;
  }


  .m-helpful-lib .response {
    display: none;
    float: right;
  }

  .m-helpful-lib.clicked .question-container {
    display: none;
  }

  .m-helpful-lib.clicked .response {
    display: block;
  }
}

.code-header {
  display: flex;
  justify-content: flex-end;
}

.copy-code-button {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-column-gap: 4px;
  border: none;
  cursor: pointer;
  padding: 4px 8px;
  background: #f9f9f7;

  &::before {
    content: "Copy";
  }

  &::after {
    content: "📋";
    display: block;
  }

  // This class will be toggled via JavaScript
  &.copied {
    &::before {
      content: "Copied!";
    }

    &::after {
      content: "✔️";
    }
  }
}

.highlight {
  margin: 0;
  pre {
    margin: 0;
  }
  .rouge-table {
    border-collapse: collapse;
    border: 2px solid #f9f9f7;
    margin: 0 0 30px 0;
    table-layout: fixed;
  }
  .gutter {
    width: 55px;
    background: $blue-95;
    border-right: 3px solid $blue-65;
    text-align: right;
  }
  .code {
    background: $white;
    pre {
      overflow: hidden;
      &:hover {
        overflow-x: scroll;
      }
    }
  }
  code {
    overflow: auto;
  }
}


table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 25px;
}

thead {
  border-top: 4px solid $blue-50;
  text-align: left;
}

th {
  font-family: $pFontBold;
  font-size: 18px;
  text-align: center;
  padding: 20px 0;
  background: #FFFFFF;
}

tr:nth-child(odd){background-color: #FAFEFF;}

tr {
  border-bottom: 0.5px solid #DBDBDB;
}

td{
  height: 95px;
  text-align: center;
  padding: 0 10px;
}

.rouge-table tr {
  background: #e8f8ff;
  text-align: left;
}

.rouge-table td {
  text-align: left;
  font-family: Consolas,"Liberation Mono",Courier,monospace;
}

.language-text {
  overflow: hidden;
  overflow-wrap: anywhere;
  width: 100%;
  display: block;
  margin-bottom: 25px;
  padding: 15px;
  font-family: monospace;
  background: $cloud-blue-95;
  border: 2px solid #f9f9f7;
  :hover {
    overflow-x: scroll;
  }
}

.language-plaintext.highlighter-rouge {
  background: #ebf7ff;
  padding: 5px;
}

button.collapse-menu, button.serie-item {
  background: transparent;
  border: none;
  cursor: pointer;
}

.highlight-replace {
  padding: 15px;
  background: #FAFEFF;
  color: #336699;
  border: 2px solid #f9f9f7;
  font-family: Consolas,"Liberation Mono",Courier,monospace;
  .replace-text {
    color: #dd2200;
    background-color: #fff0f0;
  }
}





//new styles
.tutorials-lp {
  padding-top: 72px;
  @media screen and (min-width: $md-breakpoint) {
    padding-top: 62px;
  }
  .ms-com-content.ms-com-content-header.br-nav.closed.new-nav.with-helmet.no-selector {
    min-height: 99px;
    @media screen and (min-width: $md-breakpoint) {
      height: 93px;
      min-height: auto;
    }
  }
  .component-search-bar {
    background-color: $cloud-blue-95;
    position: unset;
    width: 100%;
    padding-block: 15px;
    //margin-top: 6px;
    z-index: 6;
    &.pinned-element {
      position: fixed;
    }
    @media screen and (min-width: $md-breakpoint) {
      position: fixed;
      margin-top: 10px;
      padding-block: 12px;
    }
    .menu-header.mobile {
      padding-top: 2px;
      display: block;
      @media screen and (min-width: $md-breakpoint) {
        display: none;
      }
      .collapse-menu {
        display: inline-block;
        background: transparent;
        border: none;
        cursor: pointer;
        padding-inline: 0;
        &:before {
          background-image: url(/src/img/menu-carrot-dark-blue.svg);
          width: 30px;
          height: 30px;
          content: ' ';
          display: inline-block;
          background-repeat: no-repeat;
          vertical-align: top;
        }
      }
    }
    .search-form {
      .search-mag-icon {
        background-image: url(/src/img/search-icon.svg);
        width: 30px;
        height: 40px;
        background-repeat: no-repeat;
        background-position: 12px 12px;
        background-size: 16px;
        position: absolute;
      }
      input {
        border: none;
        border: 1px solid $muleBlue;
        border-radius: 4px;
        font-size: 16px;
        line-height: 30px;
        font-family: $sFont;
        padding-left: 40px;
        height: 40px;
        width: 100%;
      }
    }
  }

  .component-banner {
    &.component-banner-tutorials {
      @media screen and (min-width: $md-breakpoint){
        padding-top: 70px;
      }
    }
  }

  .sidebar {
    background-color: $white;
    box-shadow: 4px 0px 10px rgba(0, 0, 0, 0.1);
    transition: 0.2s;
    transform: translateX(-110%);
    position: fixed;
    z-index: 16;
    top: 0;
    width: 100%;
    @media screen and (min-width: $md-breakpoint) {
      width: 0;
      margin-top: 70px;
      z-index: 1;
      top: auto;
      transform: none;
      transition: 0.2s;
      height: 100vh;
      overflow-y: scroll;
    }
    &.active-sidebar {
      transform: translateX(0%);
      top: 0;
      //scrollbar-width: thin;
      //scrollbar-color: $navyBlue $veryLightBlue;
      @media screen and (min-width: $md-breakpoint) {
        width: 264px;
        top: auto;
        transform: none;
      }
      .row {
        .row-inside {
          padding-right: 0;
          padding-block: 0;
          .menu-header {
            @media screen and (min-width: $md-breakpoint) {
              left: 264px;
            }
            .collapse-menu {
              transform: rotate(0deg);
              transition: 0.2s;
              padding: 25px 1px;
              &:before {
                 @media screen and (min-width: $md-breakpoint) {
                   display: block;
                   padding: 0;
                   background-image: url(/src/img/menu-carrot-white.svg);
                   height: 16px;
                 }
              }
            }
          }
        }
      }
      &::-webkit-scrollbar  {
        width: 4px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $navyBlue;
        border-radius: 4px;
      }
      &::-webkit-scrollbar-track {
        background-color: $veryLightBlue;
      }
      &+.wrapper {
        .section-tiles {
          .first-tile {
            .tile-c {
              &::after {
                @media screen and (min-width: $md-breakpoint) {
                  content: none;
                }
                @media screen and (min-width: $lg-breakpoint) {
                  content: '';
                  background: url(/src/img/bg_img_4-columns.svg);
                  width: 612px;
                  height: 403px;
                  position: absolute;
                  top: 0;
                  left: 480px;
                  z-index: -1;
                }
                @media screen and (min-width: $xl-breakpoint) {
                  background: url(/src/img/bg_img_5-columns.svg);
                  left: 672px;
                }
              }
            }
          }
        }
      }
    }
    .row>.row-inside {
      padding-block: 0;
      .menu-header {
        justify-content: space-between;
        transition: 0.2s;
        display: flex;
        height: 60px;
        align-items: center;
        padding: 15px 24px 15px 0;
        @media screen and (min-width: $md-breakpoint) {
          display: block;
          background-color: $navyBlue;
          padding-right: 0;
          position: fixed;
          left: 0;
          width: 16px;
          float: right;
          box-shadow: 2px 1px 3px rgba(0, 0, 0, 0.05);
          border-radius: 0 20% 20% 0;
          height: 64px;
          margin-top: 38px;
          padding: 0;
        }
        h3 {
          display: block;
          font-family: $pFontBold;
          font-size: 22px;
          line-height: 32px;
          @media screen and (min-width: $md-breakpoint) {
            display: none;
          }
        }
        .collapse-menu {
          transform: rotate(180deg);
          transition: 0.2s;
          padding: 25px 1px;
          &:before {
            background-image: url(/src/img/close-menu-button.svg);
            height: 18px;
            width: 18px;
            position: relative;
            content: ' ';
            vertical-align: middle;
            background-repeat: no-repeat;
            display: inline-block;
            @media screen and (min-width: $md-breakpoint) {
              display: block;
              padding: 0;
              background-image: url(/src/img/menu-carrot-white.svg);
              height: 15px;
              width: 12px;
            }
          }
        }
      }
      .search-form {
        display: block;
        padding-right: 24px;
        @media screen and (min-width: $md-breakpoint) {
          display: none;
        }
        .search-mag-icon {
          background-image: url(/src/img/search-icon.svg);
          width: 30px;
          height: 40px;
          background-repeat: no-repeat;
          background-position: 12px 12px;
          background-size: 16px;
          position: absolute;
        }
        input {
          border: none;
          border: 1px solid $muleBlue;
          border-radius: 4px;
          font-size: 16px;
          line-height: 30px;
          font-family: $sFont;
          padding-left: 40px;
          height: 40px;
          width: 100%;
        }
      }
      #menu-tutorials {
        padding-top: 0;
        overflow-y: scroll;
        height: 100vh;
        margin-top: 8px;
        @media screen and (min-width: $md-breakpoint) {
          margin-top: 18px;
        }
        &.active-menu {
          padding-bottom: 190px;
          height: 100vh;
          overflow-y: scroll;
          &::-webkit-scrollbar  {
            width: 4px;
          }
          &::-webkit-scrollbar-thumb {
            background-color: $navyBlue;
            border-radius: 4px;
          }
          &::-webkit-scrollbar-track {
            background-color: $veryLightBlue;
          }
          @media screen and (min-width: $md-breakpoint) {
            height: inherit;
            overflow-y: auto;
            margin-top: 18px;
          }
        }
        .menu-wrapper {
          margin: 0;
          padding: 32px 0 0 0;
          padding-top: 0;
          @media screen and (min-width: $md-breakpoint) {
            margin-top: 16px;
            margin-bottom: 0;
            padding-left: 0;
          }
          .menu-serie {
            max-height: 100%;
            &:hover {
              overflow-y: hidden;
            }
            .menu-serie-title{
              font-size: 16px;
              line-height: 24px;
              font-family: $pFontBold;
              display: block !important;
              padding-top: 15px;
              @media screen and (min-width: $md-breakpoint) {
                padding-top: 0;
              }
              &:before {
                content: none;
              }
            }
            .menu-serie-title+.menu-items-wrapper{
              margin-left: 0px;
              padding-left: 5px;
              margin-top: 16px;
              .menu-item-wrapper{
                padding: 8px 16px;
                margin-left: 8px;
                padding-top: 0;
                cursor: auto;
                list-style-type: none;
                .serie-item-lvl-2,
                .serie-item {
                  font-family: $pFontBold;
                  color: $fontColor;
                  padding-left: 0;
                  font-size: 14px;
                  line-height: 22px;
                  vertical-align: middle;
                  border: none;
                  background-color: transparent;
                  text-align: left;
                  cursor: pointer;
                  text-indent: -23px;
                  padding-inline: 0;
                  margin-left: 0;
                  margin-bottom: 16px;
                  display: block;
                  &:before {
                    content: '';
                    background-image: url(/src/img/menu-arrow-blue.svg);
                    width: 13px;
                    height: 14px;
                    display: inline-block;
                    background-repeat: no-repeat;
                    margin-right: 10px;
                    vertical-align: middle;
                    transition: transform 0.3s ease-in-out;
                  }
                  &.active {
                    +.items-wrapper {
                      margin-bottom: 30px;
                    }
                    &:before {
                      transform: rotate(90deg);
                      margin-bottom: 0;
                    }
                  }
                }
                .items-wrapper-lvl-2,
                .items-wrapper{
                  padding-left: 16px;
                  border-left: 1px solid #DBDBDB;
                  list-style: none;
                  padding-top: 0;
                  padding-left: 16px;
                  max-height: 0;
                  overflow: hidden;
                  transition: max-height 0.2s ease-out;
                  .menu-item-lvl-2,
                  .menu-item {
                    padding: 0 0 7px 0;
                    text-indent: 0;
                    padding-left: 0;
                    margin-left: 0;
                    cursor: auto;
                    @media screen and (min-width: $md-breakpoint) {
                      padding-right: 0;
                    }
                    a {
                      display: block;
                      color: $fontColor;
                      font-size: 14px;
                      line-height: 22px;
                      font-family: $sFont;
                      border-radius: 4px;
                      transition: all 0.2s ease-in-out;
                      padding: 7px;
                      &:hover {
                        background-color: $navyBlue;
                        border-radius: 4px;
                        color: $white;
                        padding: 7px;
                        text-decoration: none;
                      }
                    }
                    &.current {
                      a {
                        background: $veryLightBlue;
                        border: 1px solid $muleBlue;
                        &:hover {
                          background: $veryLightBlue;
                          color: $black;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          @media screen and (min-width: $md-breakpoint) {
            &:last-child {
              .menu-items-wrapper {
                .menu-item-wrapper {
                  &:first-child {
                    .serie-item {
                      white-space: nowrap;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .section-tiles {
    .first-tile {
      .tile-c {
        position: relative;
        &::after {
          @media screen and (min-width: $md-breakpoint) {
            content: '';
            background: url(/src/img/bg_img_4-columns.svg);
            width: 612px;
            height: 403px;
            position: absolute;
            top: 0;
            left: 600px;
            z-index: -1;
          }
          @media screen and (min-width: $lg-breakpoint) {
            background: url(/src/img/bg_img_5-columns.svg);
            left: 672px;
          }
          @media screen and (min-width: $xl-breakpoint) {
            //content: none;
          }
        }
      }
    }
    .second-row-tiles {
      .active {
        .tile-content-wrapper {
          @media screen and (min-width: $md-breakpoint) {
            min-height: auto;
          }
          @media screen and (min-width: $lg-breakpoint) {
            min-height: 405px;
          }
          @media screen and (min-width: $xl-breakpoint) {
            min-height: 365px;
          }
        }
        .tile-title {
          @media screen and (min-width: $md-breakpoint) {
            min-height: 45px;
          }
          @media screen and (min-width: $lg-breakpoint) {
            min-height: 85px;
          }
        }
      }
      .tile-content-wrapper {
        @media screen and (min-width: $md-breakpoint) {
          min-height: 405px;
        }
        @media screen and (min-width: $lg-breakpoint) {
          min-height: 365px;
        }
      }
    }
  }
  .tutorials-content lite-youtube  {
    border-radius: 8px;
    max-width: 100%;
    cursor: pointer;
    iframe {
      height: 100%;
      border-radius: 8px;
      display: block;
    }
  }
}


.page-overview {
  .overview-title-wrapper {
    @media screen and (min-width: $md-breakpoint) {
      padding-top: 75px;
    }
    .breadcrumbs {
      text-align: left;
      ul {
        padding: 0;
        //margin-bottom: 40px;
        @media screen and (min-width: $md-breakpoint) {
          margin-top: 12px;
        }
        li {
          display: inline-block;
          a {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            color: $blue-20;
            font-family: $sFont;
            &:hover {
              text-decoration: underline;
            }
          }
          span {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            color: $blue-20;
            font-family: $sFont;
          }
          &:not(:last-child):after {
            content: ">";
            display: inline-block;
            vertical-align: middle;
            padding-left: 5px;
            padding-top: 1px;
            font-size: 12px;
            line-height: 16px;
            color: $blue-20;
            font-family: $sFont;
          }
        }
      }

    }
    h2 {
      font-family: $pFontBold;
      font-size: 33px;
      line-height: 44px;
    }
    p {
      font-family: $sFont;
      font-size: 16px;
      line-height: 24px;
      margin-top: 16px;
    }
  }
  .all-tutorials {
    .total-tutorials {
      font-family: $pFontBold;
      font-size: 22px;
      line-height: 32px;
      margin-block: 4px 16px;
      @media screen and (min-width: $sm-breakpoint) {
        margin-top: 28px;
      }
      @media screen and (min-width: $md-breakpoint) {
        margin-bottom: 24px;
      }
    }
  }
  .section-tiles {
    padding-bottom: 60px;
    .tile-wrapper {
      padding-inline: 30px;
      padding-block: 24px;
      margin-bottom: 24px;
      box-shadow: 0px 2px 4px 1px rgba(24, 24, 24, 0.16);
      filter: drop-shadow(0px 0px 2px rgba(24, 24, 24, 0.08));
      box-shadow: 0 10px 30px 0 rgba(0,0,0,0.1);
      border-radius: 16px;
      display: block;
      &:hover {
        box-shadow: 0px 12px 24px -4px rgba(24, 24, 24, 0.08), 0px 16px 32px -4px rgba(24, 24, 24, 0.16);
        box-shadow: 0 15px 44px 0 rgba(119,118,118,0.25);
        .animated-arrow-link {
          text-decoration: underline;
          &:after {
            transform: translateX(8px);
          }
        }
        .mule-button-br {
          text-decoration: none;
          background: $cloud-blue-95;
          color: $blue-20;
          border-color: $blue-20;
        }
      }
      .eyebrow {
        color: $blue-20;
        text-transform: none;
        font-size: 14px;
        line-height: 20px;
        font-family: $sFont;
        margin-bottom: 8px;
      }
      .title {
        font-family: $sFontBold;
        font-size: 16px;
        line-height: 24px;
        margin-top: 4px;
        min-height: 110px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }
      p {
        &.animated-arrow-link {
          font-size: 16px;
          line-height: 24px;
          font-family: $sFontBold;
          color: $navyBlue;
          position: relative;
          display: inline !important;
          &:after {
            @media screen and (min-width: $md-breakpoint) {
              content: '';
              background-image: url(https://www.mulesoft.com/sites/default/files/cmm_files/new-graphics-br.svgz);
              width: 18px;
              height: 15px;
              display: inline-block;
              background-position: -1676px -910px;
              background-size: 600px;
              position: relative;
              bottom: -1px;
              margin-left: 8px;
              transition: transform .5s ease-in-out;
            }
          }
          &:hover {
            text-decoration: underline;
            &:after {
              transform: translateX(8px);
            }
          }
        }
      }
    }
  }
}

// Styling scrollbar for Firefox
@supports not(#{'\selector(::-webkit-scrollbar)'}) {
            .tutorials-lp .sidebar.active-sidebar {
              scrollbar-width: thin;
              scrollbar-color: $navyBlue $veryLightBlue;
            }
          }
