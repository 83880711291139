
.tiles-wrapper{
  display: grid;
  grid-template-columns: 1fr;
  @media screen and (min-width: $sm-breakpoint) {
    /*grid-template-columns: repeat(2, 2fr);
    column-gap: 16px;*/
  }
  @media screen and (min-width: $md-breakpoint) {
    grid-template-columns: 1fr;
  }
  .component-tile{
    max-width: initial;
    margin-top: 0;
    @media screen and (min-width: $sm-breakpoint) {
      display: inline-block;
      grid-template-columns: repeat(2, 2fr);
    }
    @media screen and (min-width: $md-breakpoint) {
      max-width: 435px;
      grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (min-width: $lg-breakpoint) {
      margin-bottom: 0;
    }
    &.component-tile-a{
      background-color: transparent;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-radius: 10px;
      padding: 32px;
      .tile-content-wrapper{
        .tile-title{
          margin-top: 0;
          margin-bottom: 8px;
          font-size: 22px;
          line-height: 32px;
          color: $blue-20;
          font-family: $pFontBold;
        }
        .tile-body{
          margin-top: 0;
          margin-bottom: 16px;
          font-family: $sFont;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 30px;
        }
      }
      &:hover{
        background-color: $lightHoverBlue;
        .arrow-button{
          text-decoration: underline;
          text-decoration-thickness: 2px;
          text-underline-offset: 3px;
          color: $darkNavyBlue;
          &:before {
            background-color: $darkNavyBlue;
          }
        }
        .tile-button-container {
          .mule-button-br {
            text-decoration: none;
            background: $cloud-blue-95;
            color: $blue-20;
            border-color: $blue-20;
          }
        }
      }
    }
    &:first-of-type{
      margin-top: 32px;
      @media screen and (min-width: $sm-breakpoint){
        margin-top: 0;
      }
    }
    &:not(:first-of-type){
      @media screen and (min-width: $lg-breakpoint){
        margin-top: 8px;
        margin-bottom: 0;
      }
    }
  }
}


.tile-b {
  background: $white;
  margin-bottom: 40px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  overflow: hidden;
  &:hover {
    box-shadow: 0 15px 44px 0 rgba(119,118,118,.25);
    .mule-button-br {
      text-decoration: none;
      background: $cloud-blue-95;
      color: $blue-20;
      border-color: $blue-20;
    }
  }
  .component-tile-b {
    p.tile-image img {
      width: 100%;
    }
    .tile-content-wrapper {
      padding: 20px 30px 15px 30px;
      p.tile-title {
        font-family: $pFontBold;
        font-size: 22px;
        line-height: 32px;
        min-height: 100px;
        padding-bottom: 5px;
      }
      p.tile-body {
        font-size: 16px;
        line-height: 30px;
        max-height: 130px;
        min-height: 130px;
      }
    }
    .tile-button-container {
      padding: 0 30px 15px 30px;
    }
    p.arrow-button {
      padding: 0 30px 30px 30px;
    }
  }
}

.tutorials-lp .section-tiles .tile-c {
  margin-block: 12px;
}

.tutorials-lp .section-tiles .row-inside {
  padding-block: 0;
}

.tutorials-lp,
.cards-section {
  .tile-c, .tile-f {
    border-left: none;
    box-shadow: 0px 2px 4px 1px rgba(24, 24, 24, 0.16), 0px 0px 2px 0px rgba(24, 24, 24, 0.08);
    transition: 0.3s;
    .component-tile {

      &::before {
        display: none;
      }
      &.component-tile-f {
        .eyebrow {
          margin-bottom: 0;
        }
      }

    }
    &:hover {
      box-shadow: rgba(24, 24, 24, 0.08) 0px 12px 24px -4px, rgba(24, 24, 24, 0.16) 0px 16px 32px -4px;
    }
  }
}

.tile-c {
  border: 0;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 10px 30px rgba(0,0,0,.1);
  -moz-box-shadow: 0 10px 30px rgba(0,0,0,.1);
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  background: $white;
  border-radius: 16px;
  &:hover {
      box-shadow: 0 15px 44px 0 rgba(119,118,118,0.25);
  }
  .component-tile-c {
    position: relative;

      &:before {
        content: '';
        display: block;
        position: absolute;
        height: 8px;
        width: 100%;
        background: #032D60;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
      }


  }
  .eyebrow {
    color: $blue-20;
    text-transform: none;
    font-size: 14px;
    line-height: 20px;
    font-family: $sFont;
    padding-block: 21px 4px;
    padding-inline: 30px;
  }

  .tile-title {
    font-size: 24px;
    line-height: 32px;
    font-family: $pFontBold;
    padding-inline: 30px;
    word-break: break-word;
  }

  .tile-body {
    font-size: 16px;
    line-height: 24px;
    font-family: $sFont;
    margin-block: 16px 32px;
    padding-inline: 30px;

  }

  .tutorials-list {
    display: none;
    padding-inline: 30px;
    margin-bottom: 16px;
    a {
      color: $navyBlue;
      font-size: 14px;
      line-height: 24px;
      font-family: $sFontBold;
      display: block;
      padding-bottom: 5px;
      padding-top: 5px;
      border-bottom: 1px solid #dbdbdb;
      &:hover {
        text-decoration: underline;
      }
    }
    @media screen and (min-width: $md-breakpoint) {
      display: block;
    }
  }
  .tile-button-container {
    padding: 0 30px 15px 30px;
    @media screen and (min-width: $md-breakpoint){
      min-height: 80px;
    }
  }

  .all-tutorials {
    padding-bottom: 24px;
    padding-inline: 24px;
    @media screen and (min-width: $md-breakpoint) {
      padding-top: 14px;
      min-height: 70px;
    }
    a {
      display: inline-block;
      color: $navyBlue;
      font-size: 14px;
      line-height: 24px;
      font-family: $sFontBold;
      &.animated-arrow-link {
        color: $navyBlue;
        position: relative;
        display: inline !important;
        &:after {
          content: '';
          background-image: url(https://www.mulesoft.com/sites/default/files/cmm_files/new-graphics-br.svgz);
          width: 18px;
          height: 15px;
          display: inline-block;
          background-position: -1676px -910px;
          background-size: 600px;
          position: relative;
          bottom: -1px;
          margin-left: 8px;
          transition: transform .5s ease-in-out;
        }
      }
      &:hover {
        text-decoration: underline;
        &:after {
          transform: translateX(8px);
        }
      }
    }

  }
}

.section-tiles {
  .second-row-tiles {
    .tile-title {
      @media screen and (min-width: $md-breakpoint) {
        min-height: 85px;
      }
    }
    .tile-body {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        @media screen and (min-width: $md-breakpoint) {
          margin-top: 0;
        }
    }
  }
}

.section-tiles-down {
  padding-top: 32px;
  &:last-child {
    padding-bottom: 30px;
    @media screen and (min-width: $md-breakpoint) {
      padding-bottom: 60px;
    }
  }

  .tile-down-title-container {
    display: flex;
    align-items: center;
    gap: 16px;
    padding-bottom: 0;
    padding-top: 10px;
    img {
      display: none;
      @media screen and (min-width: $md-breakpoint) {
        display: block;
        width: 48px;
      }
    }
  }
  .tile-down-title {
    color: $navyBlue;
    font-family: $pFontBold;
  }
}

.all-tutorials-title {
  padding-top: 20px;
  font-size: 40px;
  line-height: 48px;
  @media screen and (min-width: $md-breakpoint) {
    padding-top: 60px;
  }
}

.tile-f {
  border-left: 5px solid $navyBlue;
  border-radius: 16px;
  margin-top: 8px;
  margin-bottom: 5px;
  box-shadow: 0px 0px 2px rgba(24, 24, 24, 0.08), 0px 2px 4px 1px rgba(24, 24, 24, 0.16);
  -ms-box-shadow: 0px 0px 2px rgba(24, 24, 24, 0.08), 0px 2px 4px 1px rgba(24, 24, 24, 0.16);
  -moz-box-shadow: 0px 0px 2px rgba(24, 24, 24, 0.08), 0px 2px 4px 1px rgba(24, 24, 24, 0.16);
  -webkit-box-shadow: 0px 0px 2px rgba(24, 24, 24, 0.08), 0px 2px 4px 1px rgba(24, 24, 24, 0.16);
  box-shadow: 0 10px 30px 0 rgba(0,0,0,.1);
  position: relative;
  &:hover {
    box-shadow: 0 15px 44px 0 rgba(119,118,118,0.25);
  }
  @media screen and (min-width: $md-breakpoint) {
    margin-top: 24px;
    border-left: none;
  }
  .component-tile-f {
    @media screen and (min-width: $md-breakpoint) {
      &:before {
        content: '';
        display: block;
        position: absolute;
        height: 8px;
        width: 100%;
        background: #032D60;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
      }
    }
    .tile-content-wrapper {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-template-rows: 1fr;
      border-radius: 16px;
      &:hover {
        /*box-shadow: 0px 12px 24px -4px rgba(24, 24, 24, 0.08), 0px 16px 32px -4px rgba(24, 24, 24, 0.16);
        -ms-box-shadow: 0px 12px 24px -4px rgba(24, 24, 24, 0.08), 0px 16px 32px -4px rgba(24, 24, 24, 0.16);
        -moz-box-shadow: 0px 12px 24px -4px rgba(24, 24, 24, 0.08), 0px 16px 32px -4px rgba(24, 24, 24, 0.16);
        -webkit-box-shadow: 0px 12px 24px -4px rgba(24, 24, 24, 0.08), 0px 16px 32px -4px rgba(24, 24, 24, 0.16);
        box-shadow: 0 15px 44px 0 rgba(119,118,118,.25);*/
        .mule-button-br {
          text-decoration: none;
          background: $cloud-blue-95;
          color: $blue-20;
          border-color: $blue-20;
        }
      }
      @media screen and (min-width: $md-breakpoint) {
        display: block;
      }
    }

    .tile-button-container {
      padding-left: 16px;
      @media screen and (min-width: $md-breakpoint){
        padding: 0 30px 15px 30px;
      }
    }
    .blue-bg {
      background-repeat: no-repeat !important;
      background-position: center !important;
      height: 76px;
      width: 76px;
      @media screen and (min-width: $md-breakpoint) {
        width: auto;
        height: 96px;
        background-position: 85% 50% !important;
      }
    }
    .tile-body {
      display: none;
      @media screen and (min-width: $md-breakpoint) {
        //display: block;
        font-size: 16px;
        line-height: 24px;
        padding-inline: 30px;
        padding-top: 8px;
        min-height: 105px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }
    }
    .eyebrow {
      display: none;
      @media screen and (min-width: $md-breakpoint) {
        display: block;
        margin-bottom: 8px;
        padding-inline: 30px;
        padding-top: 24px;
        text-transform: none;
        color: $blue-20;
        font-size: 14px;
        line-height: 20px;
        font-family: $sFont;
      }
    }
    .tile-title {
      font-family: $pFontBold;
      grid-row: 1 / 2;
      grid-column: 2 / 3;
      padding-block: 12px;
      padding-inline: 16px;
      @media screen and (min-width: $md-breakpoint){
        padding-inline: 30px;
        padding-top: 0;
        min-height: 80px;
      }
    }
    p:last-child {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
      font-size: 16px;
      line-height: 30px;
      color: $eyebrowGrey;
      padding-left: 16px;
      padding-top: 0px;
      &:hover {
        text-decoration: underline;
      }
      @media screen and (min-width: $md-breakpoint) {
        padding-block: 24px;
        padding-inline: 24px;
        line-height: 24px;
        color: $navyBlue;
        font-family: $sFontBold;
      }
    }
  }
}


.tile-d {
  border-left: none;
  box-shadow: 0px 2px 4px 1px rgba(24, 24, 24, 0.16), 0px 0px 2px 0px rgba(24, 24, 24, 0.08);
  transition: 0.3s;
  border-radius: 16px;
  margin-top: 24px;
  &:hover {
    box-shadow: rgba(24, 24, 24, 0.08) 0px 12px 24px -4px, rgba(24, 24, 24, 0.16) 0px 16px 32px -4px;
  }
  @media screen and (min-width: $md-breakpoint) {
    margin-top: 24px;
    border-left: none;
  }
  .component-tile-d {
    .tile-content-wrapper {
      &:hover {
        /*box-shadow: 0px 12px 24px -4px rgba(24, 24, 24, 0.08), 0px 16px 32px -4px rgba(24, 24, 24, 0.16);
        -ms-box-shadow: 0px 12px 24px -4px rgba(24, 24, 24, 0.08), 0px 16px 32px -4px rgba(24, 24, 24, 0.16);
        -moz-box-shadow: 0px 12px 24px -4px rgba(24, 24, 24, 0.08), 0px 16px 32px -4px rgba(24, 24, 24, 0.16);
        -webkit-box-shadow: 0px 12px 24px -4px rgba(24, 24, 24, 0.08), 0px 16px 32px -4px rgba(24, 24, 24, 0.16);
        box-shadow: 0 15px 44px 0 rgba(119,118,118,.25);*/
        .mule-button-br {
          text-decoration: none;
          background: $cloud-blue-95;
          color: $blue-20;
          border-color: $blue-20;
        }
      }

      .flex {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-inline: 24px;
        padding-block: 24px 18px;
      }

      @media screen and (min-width: $md-breakpoint) {
        display: block;
      }

      .tile-title {
        font-family: $pFontBold;
        margin-bottom: 10px;
        @media screen and (min-width: $sm-breakpoint){
          min-height: 60px;
        }
      }

      .tile-body {
        display: block;
        font-size: 16px;
        line-height: 24px;
        color: $fontColor;
        font-family: $sFont;
        margin-bottom: 20px;
        &:hover {
          text-decoration: none;
        }
        @media screen and (min-width: $sm-breakpoint) {
          min-height: 75px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }

      .eyebrow {
        display: none;
        @media screen and (min-width: $md-breakpoint) {
          display: block;
          margin-bottom: 8px;
          padding-inline: 30px;
          padding-top: 24px;
          text-transform: none;
          color: $blue-20;
          font-size: 14px;
          line-height: 20px;
          font-family: $sFont;
        }
      }
    }
  }
}

.tile-g {
  .component-tile-g {
    margin-bottom: 70px;
    cursor: pointer;
    .ambassadors {
      text-align: center;
      .ambassador-media {
        position: relative;
        display: inline-block;
        &:after {
          content: " ";
          display: block;
          background-image: url("/src/img/ambassadors/MS_AmbassadorsBadge.png");
          background-size: contain;
          height: 98px;
          width: 98px;
          position: absolute;
          right: 30px;
          bottom: 5px;
          border-radius: 50%;
          box-shadow: 0 22px 44px 0 rgba(0,0,0,0.10);
        }
        img {
          max-width: 269px;
          width: 100%;
          margin: 0 auto;
          border-radius: 50%;
          box-shadow: 0 22px 44px 0 rgba(0,0,0,0.10);
        }
      }
      .ambassador-info {
        .name {
          font-family: $pFontBold;
          font-size: 20px;
          padding-top: 38px;
          padding-bottom: 8px;
        }
        .country,
        .years {
          font-family: $sFontLight;
          font-size: 20px;
          display: block;
          padding-bottom: 8px;
        }
      }
      &.alumni {
        .ambassador-media:after {
            background-image: url(/src/img/ambassadors/alumni-logo.png);
        }
      }
    }
  }
}