.try-platform-section,
.start-ambassador-section{
  padding: 0;
  margin: 0;
  .row-inside{
    padding-top: 40px;
    padding-bottom: 16px;
    @media screen and (min-width: $sm-breakpoint){
      padding-bottom: 32px;
    }
    @media screen and (min-width: $md-breakpoint){
      padding-top: 60px;
      padding-bottom: 40px;
    }
    .try-platform-wrapper,
    .start-ambassador-wrapper {
      height: 300px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: $blue-50;
      padding: 60px 35px;
      border-radius: 16px;
      @media screen and (min-width: $sm-breakpoint){
        background-size: cover;
      }
      @media screen and (min-width: $lg-breakpoint){
        background-size: contain;
      }
      .title {
        margin: 0;
        font-family: $pFontBold;
        font-size: 33px;
        line-height: 39px;
        text-align: center;
        color: $blue-95;
        margin-bottom: 30px;
      }
      a.cta{
        margin-top: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 270px;
        padding: 16px;
        font-family: $sFontBold;
        @media screen and (min-width: $sm-breakpoint){
          max-width: 200px;
        }
        @media screen and (min-width: $md-breakpoint){
          max-width: 200px;
        }
        @media screen and (min-width: $lg-breakpoint){
          margin-top: 50px;
        }
      }
    }
  }
}