.section-anypoint-platform {
  &.benefit-row {
    .row {
      .row-inside {
        display: flex;
        flex-direction: column-reverse;
        text-align: center;
        @media screen and (min-width: $md-breakpoint) {
          flex-direction: row;
          align-items: center;
        }
      }
    }
    &.tutorial-block {
      .img-block {
        @media screen and (min-width: $md-breakpoint) {
          display: none;
        }
        @media screen and (min-width: $xl-breakpoint) {
          display: block;
        }
      }
    }


  }
  .text-container {
    text-align: left;
    h2 {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 20px;
      @media screen and (min-width: $md-breakpoint) {
        font-size: 44px;
        line-height: 56px;
      }
      span {
        display: block;
      }
    }
    .body {
      margin-bottom: 24px;
    }
    .cta {
      margin-bottom: 16px;
    }
    p {
      font-size: 16px;
      line-height: 24px;
    }
  }
  .image-container {
    display: none;
    @media screen and (min-width: $sm-breakpoint) {
      display: block;
    }
  }
}