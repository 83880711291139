.component-carousel {
    position: relative;

  .carousel-indicators {
    position: absolute;
    bottom: 60px;
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    left: 0;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 15;
    @media screen and (min-width: $sm-breakpoint){
      bottom: 0px;
    }
    li {
      background-color: #c4c4c4;
      width: 8px;
      height: 8px;
      padding: 0;
      margin: 0 5px;
      cursor: pointer;
      border-radius: 10px;

      &.active {
        background-color: $muleBlue;
      }
    }
    @media screen and (min-width: $md-breakpoint){
      display: none;
    }
  }

  .carousel-cards-controls {
    display: none;
    > * {
      display: inline-block;
      background: #E8F8FF;
      width: 58px;
      height: 58px;
      border-radius: 50%;
      cursor: pointer;
      position: relative;
      user-select: none;

      &:hover {
        background: $muleBlue;
        &:after {
          border-color: white;
        }
      }

      &:after {
        content: '';
        position: absolute;
        top: 26px;
        left: 26px;
        border: solid $muleBlue;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
      }

      &.next-card {
        margin-left: 15px;
      }

      &.next-card:after {
        left: 23px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }

    }

    @media screen and (min-width: $md-breakpoint){
      display: block;
      position: absolute;
      top: -105px;
      right: 0;
      z-index: 0;
    }
  }

  .carousel-cards-wrapper {
    width: 400%;
    transition: 1s ease margin-left;
    transform: translate3D(0,0,0);
    min-height: 380px;
    margin-bottom: 60px;
    @media screen and (min-width: $sm-breakpoint){
      min-height: 530px;
      margin-bottom: 120px;
    }
    @media screen and (min-width: $md-breakpoint){
      margin-bottom: 0px;
    }

  }

  .carousel-cards {
    font-size: 0;
    display: inline-block;

    .carousel-card {
      background-color: white;
      max-width: 278px;
      min-height: 278px;
      display: inline-block;
      vertical-align: top;
      margin-right: 20px;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      border-radius: 16px;
      overflow: hidden;
      .carousel-card-top {
          height: 64px;
          overflow: hidden;
          position: relative;
          pointer-events: none;

          .vidyard-player-container {
            height: auto !important;
            width: 105% !important;
            position: absolute;
            left: 51%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
      }

      &.active {
        transform: translate3D(0,0,0);

        .carousel-card-top {
          @media screen and (min-width: $sm-breakpoint){
            pointer-events: auto;
            height: 320px;
          }
        }

        .vidyard-player-container .play-button {
          display: block;
          opacity: 1;
          background-color: $blue-50 !important;
          &:hover {
            background-color: #E8F8FF !important;

            .arrow {
                border-left-color: $muleBlue;
            }
          }

          @media screen and (min-width: $sm-breakpoint){
            top: 50%;
          }


        }

        @media screen and (min-width: $sm-breakpoint){
          max-width: 572px;
          min-width: 572px;

          animation: carousel-card-zoom 0.1s linear;
          box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
          cursor: pointer;

          .carousel-card-play {
            display: none;
          }
        }

        .carousel-card-bottom {
          padding: 35px 24px;
          max-height: 215px;

          .carousel-card-play {
              pointer-events: auto;
          }

          @media screen and (min-width: $sm-breakpoint){
            max-height: 160px;
          }
        }
      }


      .vidyard-player-container .play-button {
          top: 1000px;
      }

      .carousel-card-bottom {
        position: relative;
        padding: 35px 24px;

        .carousel-card-play {
            position: absolute;
            top: -28px;
            left: 50%;
            transform: translateX(-50%);
            background: $muleBlue;
            width: 56px;
            height: 56px;
            border-radius: 50%;
            pointer-events: none;
        }

        .carousel-card-play:before {
            content: '';
            position: absolute;
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 8.25px 0 8.25px 13.75px;
            border-color: transparent transparent transparent #ffffff;
            top: 50%;
            left: 50%;
            transform: translate(-40%, -50%);
        }

        .carousel-card-title {
          font-family: $pFontBold;
          font-size: 22px;
          line-height: 32px;
        }


        .carousel-card-eyebrow {
          letter-spacing: initial;
          margin-bottom: 8px;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          text-transform: none;
          font-family: $sFont;
          color: $blue-20;
        }

      }
      &:hover {
        box-shadow: 0 25px 60px rgba(0, 0, 0, 0.2);
      }
    }
  }

  @keyframes carousel-card-zoom {
    0% {
        transform: scale(0.82);
    }
    100% {
        transform: scale(1);
    }
  }
}
