.bg-gradient-color {
  background: linear-gradient(to bottom, $muleBlue 27%, #005295 60%, $darkNavyBlue 95%);
  color: $white;
  @media screen and (min-width: $md-breakpoint) {
    background: linear-gradient(-90deg, $muleBlue 27%, #005295 40%, $darkNavyBlue 95%);
    background: -webkit-linear-gradient(left, $darkNavyBlue 0, $muleBlue 100%);
  }
}

.light-blue-to-bottom {
  background: -webkit-linear-gradient(bottom,$white 0%, $cloud-blue-95-light 100%);
  background: linear-gradient(to bottom,$white 0%, $cloud-blue-95-light 100%);
}

.full-dark-navy-blue-bg{
  background: $darkNavyBlue;
}

.full-light-blue-bg{
  background: $veryLightBlue;
}

.full-mule-blue-bg{
  background: $cloud-blue-95;
}

.half-full-light-blue-bg-b {
  background: linear-gradient(180deg, transparent 50%, $veryLightBlue 50%);
}

.half-light-blue-bg {
  background: linear-gradient(180deg, $veryLightBlue 82.5%, transparent 50%);
  @media screen and (min-width: $sm-breakpoint){
    background: linear-gradient(180deg, $veryLightBlue 75%, transparent 50%);
  }
  @media screen and (min-width: $md-breakpoint){
    background: linear-gradient(180deg, $veryLightBlue 50%, transparent 50%);
  }
}

.half-light-blue-bg-b
{
  background: linear-gradient(180deg,transparent 82.5%, $cloud-blue-95-light 50%);
  @media screen and (min-width: $sm-breakpoint){
    background: linear-gradient(180deg, transparent 75%, $cloud-blue-95-light 50%);
  }
  @media screen and (min-width: $md-breakpoint){
    background: linear-gradient(180deg, transparent 50%, $cloud-blue-95-light 50%);
  }
}

.three-quarters-light-blue-bg-t
{
  background: linear-gradient(0deg, transparent 10%, $veryLightBlue 10%);
  @media screen and (min-width: $sm-breakpoint){
    background: linear-gradient(0deg, transparent 30%, $veryLightBlue 30%);
  }
  @media screen and (min-width: $md-breakpoint){
    background: linear-gradient(0deg, transparent 30%, $veryLightBlue 30%);
  }
}

.three-quarters-light-blue-bg-b
{
  background: linear-gradient(180deg, transparent 33%, $cloud-blue-95-light 10%);
  @media screen and (min-width: $sm-breakpoint){
    background: linear-gradient(180deg, transparent 33%, $cloud-blue-95-light 30%);
  }
  @media screen and (min-width: $md-breakpoint){
    background: linear-gradient(180deg, transparent 33%, $cloud-blue-95-light 30%);
  }
}

//WES backgrounds
.light-top-gradient {
  background: linear-gradient(180deg,#e9f7ff 0,#fff 100%);
  font-size: 48px;
  line-height: 56px;
}

.light-blue-background {
  background-color: $cloud-blue-95-light;
}

.gradient-to-lb {
  background: linear-gradient(0deg, $cloud-blue-95-light 0%, rgba(232, 248, 255, 0) 100%);
}



