.component-main-content-img {
  .image {
    text-align: center;
    img {
      max-width: 80%;
      height: auto;
      width: auto;
      @media screen and (min-width: $md-breakpoint){
        max-width: 100%;
      }
    }
  }
  .body-text {
    p {
      font-size: 20px;
      text-align: center;
      line-height: 30px;
      padding-bottom: 70px;
      @media screen and (min-width: $md-breakpoint) {
        font-size: 24px;
        line-height: 32px;
        text-align: inherit;
      }
    }
  }
}